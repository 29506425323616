import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCol,[_c('TherapyHeading',{staticClass:"mb-4",attrs:{"data":_vm.member,"member_load":_vm.getMembers}}),_c(VDivider,{staticClass:"mb-4"}),_c(VRow,{staticClass:"mt-8",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('UserBaseDetails',{attrs:{"details":_vm.user_Details}})],1),_c(VSpacer),(_vm.member.user_id)?_c(VCol,{attrs:{"cols":"8"}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }