<template>
    <v-container>
        <template class="pt-5">
          <v-row class="pt-15 pb-5">
            <v-checkbox
              color="red"
              label="Select All"
              v-model="selectAll"
              @change="toggleSelectAll"
            ></v-checkbox>
        </v-row> 
          <v-row justify="center" >
        
              <v-row>
                <v-col sm="6" v-for="(item, index) in selectedWidget">
                  <v-checkbox
                    :key="item.id"
                    :value="item.id"
                    :label="item.title"
                    v-model="accesedPaths"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-row>
            
        </template>
        <v-row>
          <v-btn @click="upsertPlan()" class="my-4" large color="primary">
                <v-icon class="mr-2">mdi-check</v-icon>
                  Save
            </v-btn>
           
        </v-row>
        
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "SideBarMenuAccess",
    data: ()=>({
      panel: [],
      selectAll: false,
      selectedWidget: [], 
      selectedFreeWidget: [], 
      selectedProWidget: [], 
      plans: [],
      selectedItems: [],
      accesedPaths: [],
      loading: true,
      editing: null
    }),
    props:{
      userId: {
          type: Number,
          required: true
        }
    },
    methods: {
      toggleSelectAll() {
      if (this.selectAll) {
        this.accesedPaths = this.selectedWidget.map(item => item.id);
      } else {
        this.accesedPaths = [];
      }
    },
      upsertPlan(){
          this.checkUser()
          authClient.post(`sidebar-access/store-access/${this.userId}`, { accessIds: this.accesedPaths}).then(res=>{
       
            if(res.data.status){
            this.$toast.success({
              title: 'Ok',
              message: "Success",
            });
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: "Error",
          });
          }
          }).catch(err=>{
            console.log(err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      getPackages(){
        this.checkUser();
        this.loading = true;
        authClient.get('/sidebar-access/getAll').then(res=>{
          this.selectedWidget = res.data
          this.viewMenuAccessedItems();
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
      },
      viewMenuAccessedItems(){
       this.checkUser();
        this.loading = true;
        authClient.get(`/sidebar-access/getAdmin/${this.userId}`).then(res=>{
          this.accesedPaths = res.data
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
      },
      checkUser(){

        if( this.userId == undefined) {
          this.$router.push({name: 'Manage Users'});
        }

      }
    },
    mounted() {
      this.getPackages()
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  