<template>
    <v-container>
      <v-row no-gutters>
        <v-btn
          @click="BackToUser()"
          class="mr-2"
          outlined
          large
          fab
        >
        <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-text-field v-model="search" outlined rounded label="Search User..."></v-text-field>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="gymUserData">

            <template v-slot:item.gym_trainer_id="{ item }">
              <v-chip dark :color="item.gym_trainer_id  === null ? 'warning':'green'">{{ item.gym_trainer_id === null ? 'No':'Yes' }}</v-chip>
            </template>

          <template v-slot:item.feedback="{ item }">
            <v-btn v-if="item.feedback != null" outlined rounded @click="descriptionViewDialog = true; rating = item.feedback">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.completed_steps="{ item }">
            <v-progress-circular class="my-3"
            :value="Math.floor((item.completed_steps / item.steps) * 100)"
            :size="75"
            :width="10"
            color="green"
            >
            {{ Math.floor((item.completed_steps / item.steps) * 100) }} %
        </v-progress-circular>
            
          </template>
  
          <template v-slot:item.ad_url="{ item }">
            <v-btn outlined rounded target="_blank" :href="item.ad_url">
              Visit
            </v-btn>
          </template>

  
          <template v-slot:item.actions="{ item }">
            <v-btn @click="showDeleteConfirmation(item, deleteItem)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn v-if="item.gym_trainer_id !== null" @click="viewDetails(item)" v-bind="attrs"
                     v-on="on" icon>
                <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
        <AddNote :notes_obj='courtesy_Notes' :domain="domain" :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Feedback</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            1.How was your Workout?
            <v-rating background-color="orange lighten-3"  readonly color="orange" v-model="rating['1']"></v-rating>
            <v-spacer/>
            2.How  was your cardio going between Workout?
            <v-rating background-color="orange lighten-3"  readonly color="orange" v-model="rating['2']"></v-rating>
            <v-spacer/>
            3.How is your nutrition going?
            <v-rating background-color="orange lighten-3"  readonly color="orange" v-model="rating['3']"></v-rating>
            <v-spacer/>
            4.How are your energy level? 
            <v-rating background-color="orange lighten-3"  readonly color="orange" v-model="rating['4']"></v-rating>
            <v-spacer/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "ComGymUsers",
    props:{
      member: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      loading: true,
      domain:'',
      orderList:[],
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      courtesy_Notes: null,
      rating:{},
      gymUserData: [],
      headers: [
        {text: "ID", value: "id"},
        {text: "Title", value: "title"},
        {text: "Completed Percentage", value: "completed_steps"},
        {text: "Feedback", value: "feedback"},
        {text: "Actions", value: "actions"},
      ],
      search: '',
    }),
    methods: {
      deleteItem(item) {
          authClient.post(`/fitness/workouts/delete/${item.id}`,{
            tableId: 8,
            resultId: item.id
          }).then((response) => {
            let data = response.data
            if(data.success){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getGymUsers()
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      getGymUsers() {
        authClient.post('/fitness/gym/workouts/actions/list', {
            "trainer_id": this.$route.params.id,
            "user_id" : this.member.id
        }).then((response) => {
            this.gymUserData = response.data
            this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      viewDetails(item) {
        this.$router.push({
            name: 'Client Workouts', // Use the route name
            params: {  workout_plan : item.workout_plan, member : this.member }, // Pass the 'id' parameter if needed
          });
      },
      BackToUser(item) {
        this.$router.push({
            name: 'Gym Member Details', // Use the route name
            params: { id: this.$store.state.user.id, member: this.member }, // Pass the 'id' parameter if needed
          });
      },
    },
    mounted() {
      if(!this.member){
        if(this.$store.state.user.role === 'gym'){
        if(this.$router.currentRoute.path !== '/gyms/' + this.$store.state.user.id + '/details'){
          this.$router.push('/gyms/' + this.$store.state.user.id + '/details');
        }
    }
      }
      this.getGymUsers();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  