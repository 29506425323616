<template>
    <v-container>
      <v-row no-gutters>
        <v-text-field v-model="search" outlined rounded label="Search Holiday..."></v-text-field>
        <v-btn :disabled="loading" @click="addStoryDialog = true;editExercise = null; isEdit = false" class="ml-2" rounded height="56" elevation="0" dark>
          <v-icon>mdi-plus</v-icon>
          Add Holiday
        </v-btn>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="holidaysData">
          <template v-slot:item.description="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
              View
            </v-btn>
          </template>

          <template v-slot:item.holiday_dates="{ item }">
            <v-btn outlined rounded @click="modal_3 = true; selectedDates = item.holiday_dates.map(item=>item.date)">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.image="{ item }">
            <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.image">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.ad_url="{ item }">
            <v-btn outlined rounded target="_blank" :href="item.ad_url">
              Visit
            </v-btn>
          </template>

          <template v-slot:item.status="{ item }">
          <v-chip dark  :color="item.status == 1 ? 'green' : 'warning'">{{ item.status == 1 ? "Yes" : "No"}}</v-chip>
        </template>

  
          <template v-slot:item.actions="{ item }">
            <v-btn @click="isEdit = true; holiday_edit = item; addStoryDialog = true" icon >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="showDeleteConfirmation(item, deleteItem)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
        <AddHolidayDialog :data='holiday_edit'  :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
            ref="dialog"
            v-model="modal_3"
            :return-value.sync="date_range"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
            </template>
            <v-date-picker
              readonly
              v-model="selectedDates"
              multiple
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal_3 = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import AddHolidayDialog from "@/components/AddHolidayDialog.vue";
  
  export default {
    name: "HolidayManage",
    components: {AddHolidayDialog},
    data: () => ({
      loading: true,
      holiday_edit:{},
      modal_3:false,
      selectedDates:[],
      orderList:[],
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      categories:[],
      courtesy_Notes: null,
      holidaysData: [],
      headers: [
        {text: "Name", value: "name"},
        {text: "Date Duration", value: "holiday_dates"},
        {text: "Country", value: "country"},
        {text: "Description", value: "description"},
        {text: "Posted", value: "status"},
        {text: "Actions", value: "actions"},
      ],
      search: '',
    }),
    methods: {
      deleteItem(item) {
          authClient.post('delete',{
            tableId: 17,
            resultId: item.id
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getNotes()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.ad_img
            }).then((response) => {
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      statusChange(id, status) {
        authClient.post('/status/change',{
            status: status,
            tableId: 3,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getNotes()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
      },
      getNotes() {
        authClient.get('/holiday/list').then((response) => {
          let data = response.data[0]
          if(data.status){
            this.holidaysData = data.data.result;
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getNotes();
      }
    },
    mounted() {
      this.getNotes();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  