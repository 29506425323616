<template>
    <v-container>
        <v-card :class=" color_type == 1 ? 'pa-4 text-end green-background' : 'pa-4 text-end yellow-background'">
            <v-row justify="space-between" class="">
                <v-card-title class="headline">{{ title }}</v-card-title>
                <v-icon x-large class="icon-img pr-4" >{{ icon_img }}</v-icon>
            </v-row>
           <v-card-subtitle class="text-start dark-gray--text display-count">{{ count }}</v-card-subtitle>
         </v-card>    
    </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import { integer } from "vuelidate/lib/validators";
export default {
 name: 'CardView',
 props:{
    count:{
        type:integer,
        require: true
    },
    title:{
        type:String,
        require: true
    },
    icon_img:{
        type:String,
        require: true
    },
    color_type:{
        type:integer,
        require: true
    }
 }

}
</script>

<style scoped>
/* Custom styles for the Dashboard component */
.green-background {
 background-color: #4caf50; /* Light blue */
 
}

.yellow-background {
 background-color: #fb8c00; /* Light blue */
 
}


.headline{
    font-size: 1.3rem !important;
    color: rgba(255, 255, 255);
}

.icon-img{
    color: rgba(255, 255, 255);
}

.display-count{
    
    font-size: 1.8rem !important;
    color: white !important; /* Dark gray */
}

.dark-gray--text {
 color: white; /* Dark gray */
}

.v-card {
 border-radius: 10px;
 box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.v-icon {
 margin-bottom: 1rem;
}

.v-card-title {
 font-family: 'Quicksand', sans-serif;
}

.v-card-subtitle {
 color: white; /* Dark gray */
}
</style>
