import { render, staticRenderFns } from "./exclusiveGymServiceSubscription.vue?vue&type=template&id=fafad164&scoped=true&"
import script from "./exclusiveGymServiceSubscription.vue?vue&type=script&lang=js&"
export * from "./exclusiveGymServiceSubscription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fafad164",
  null
  
)

export default component.exports