<template>
    <v-container>
      <v-card outlined elevation="0">
        <v-data-table
            :headers="headers"
            :items="transactions"
            :loading="loading"
            :items-per-page="10"
            class="elevation-1">
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at.split('T')[0] }}
          </template>
          <template v-slot:item.type="{ item }">
            <v-chip dark :color="item.type === 'Credit' ? 'green' : 'red'">{{ item.type }}</v-chip>
          </template>
          <template v-slot:item.amount="{ item }">
            <span v-if="item.type === 'Credit'">+{{ item.amount }}</span>
            <span v-else>-{{ item.amount }}</span>
          </template>
        </v-data-table>
      </v-card>

    </v-container>
  </template>
  
  <script>
  
  export default {
    name: "GymSubscribedClients",
    props:{
      members:{
        required: true
      }
    },
    data: () => ({
      isShowTopup: true,
      topupDialog: false,
      transactions: [],
      headers: [
        { text: 'User Id', value: 'user.id' },
        { text: 'Name', value: 'user.name' },
        { text: 'Email', value: 'user.email' },
      ],
    }),
    methods:{
      close(){
        this.getTransactions()
        this.topupDialog = false;
        this.$emit('close');
      }
    },
    mounted() {
        this.transactions = this.members;
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  