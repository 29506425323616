<template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <span class="headline">{{ edit ? 'Edit':'Add' }} Holiday</span>
          <v-spacer/>
          <v-btn @click="$emit('close')" icon color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider class="mb-4"/>
      <v-card-text>
        <v-form ref="form" v-model="valid">
            <v-row no-gutters>
            <v-col class="mr-1">
              <v-text-field outlined  :rules="[rules.required]"  label="Name" v-model="title"></v-text-field>
            </v-col>
            <v-col class="mr-1">
              <v-autocomplete :rules="[rules.required]" :items="$store.state.countries" item-text="name" item-value="code"  v-model="country_code" outlined label="Country"/>
            </v-col>
          </v-row>
          
            <v-radio-group v-model="date_Selection" row class="pt-0 mt-0">
              <v-row>
              <v-col>
                  <v-radio
                  label="Individual Date"
                  value="1"
                ></v-radio>
              </v-col>
              <v-col>
                  <v-radio
                    label="Date Range"
                    value="2"
                  ></v-radio>
              </v-col>
              <v-col>
                  <v-radio
                    label="Various Date"
                    value="3"
                  ></v-radio>
              </v-col>
            </v-row>
            </v-radio-group>
          
          <!-- single date -->

          <v-dialog
                  v-if="date_Selection == '1'"
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
              >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  :rules="[rules.required]"
                      outlined
                      v-model="date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="date"
                  scrollable
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                      Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date);modal = false"
                  >
                      OK
                  </v-btn>
                  </v-date-picker>
          </v-dialog>

          <!-- Date Range -->
          <v-dialog
            v-if="date_Selection == '2'"
            ref="dialog"
            v-model="modal_2"
            :return-value.sync="date_range"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              :rules="[rules.required]"
                class="rounded-lg"
                prepend-icon="mdi-calendar"
                outlined
                readonly
                v-model="dateRangeText"
                label="Date Range"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dates= []"
              >
                Reset
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="modal_2 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date_range);modal_2 = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <!-- Date Variations -->
          <div v-if="date_Selection == '3'">
            <v-dialog
            ref="dialog"
            v-model="modal_3"
            :return-value.sync="date_range"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                :rules="[rules.required]"
                v-model="various_dates"
                multiple
                required
                chips
                outlined
                small-chips
                label="Multiple Date picker"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-combobox>
            </template>
            <v-date-picker
              v-model="various_dates"
              multiple
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="various_dates= []"
              >
                Reset
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="modal_3 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date_range);modal_3 = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          </div>

          <v-textarea
          :rules="[rules.required]"
            name="input-1"
            rows="3"
            v-model="description"
            outlined
            label="Description"
          ></v-textarea>
          <v-row justify="end" no-gutters>
            <v-btn :loading="loading" @click="saveOrUpdate" width="128" elevation="0"  height="56" color="primary">Save</v-btn>
          </v-row>
      </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "AddHolidayDialog",
    props: {
      edit: Boolean,
      categories: Array,
      data: Object
    },
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    data: () => ({
      rules: {
      required: value => !!value || 'Required.',
    },
      loading: false,
      dates: [],
      country_code:"",
      various_dates:[],
      date_range:'',
      description: '',
      date_Selection:'1',
      valid:false,
      title: '',
      sub_total: '',
      tax: '',
      total: '',
      modal_3:false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      modal_2:false,
      menu2: false,

    }),
    methods:{
      updateForm(){

        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;

          authClient.post('/holiday/update', {
            "holidayId": this.data.id,
            "name" : this.title,
            "country" : this.country_code,
            "description" : this.description,
            "type" : this.date_Selection,
            "date" : this.date,
            "date_range" : this.dates,
            "date_variation" : this.various_dates,
          }).then((response) => {
                this.$emit('close');
                this.loading = false;
                // this.loadData()
                let response_data = response.data[0]
                if(response_data.status){
                  this.$toast.success({
                    title: 'Ok',
                    message: response_data.message,
                });
                }else{
                  this.loading = false;
                  this.$toast.error({
                    title: 'error',
                    message: response_data.message,
                });
                }
              }).catch((error) => {
                console.log(error)
                this.loading = false;
                this.$toast.error({
                    title: 'error',
                    message: 'error',
                });
              });
            }else{
            this.loading = false
          }  
      },
      submitForm(){
        
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;

          authClient.post('/holiday/add', {
            "name" : this.title,
            "country" : this.country_code,
            "description" : this.description,
            "type" : this.date_Selection,
            "date" : this.date,
            "date_range" : this.dates,
            "date_variation" : this.various_dates,
          })
          .then((response) => {
              this.$emit('close');
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      
          }else{
            this.loading = false
          }
      },
      saveOrUpdate(){
        this.loading = true;
        if(this.edit) {
          this.updateForm();
        } else {
          this.submitForm();
        }
      }
      
    },
    mounted() {
      if(this.edit){
        this.title = this.data.name;
        this.country_code = this.data.country;
        this.description = this.data.description;
        this.date_Selection = this.data.date_type;
        if( this.data.holiday_dates.length > 0){
          this.date = this.data.date_type == '1' ? this.data.holiday_dates[0].date : "";
          this.dates = this.data.date_type == '2' ? this.data.holiday_dates.map(item=>item.date) : [];
          this.various_dates = this.data.date_type == '3' ? this.data.holiday_dates.map(item=>item.date) : [];
        }else{
          this.date_variation = [];
          this.dates = [];
          this.date = ""
        }
        
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  