<template>
  <v-container>
      <v-row no-gutters class="mb-4">
        <v-card to="/pending/gym-services" outlined hover ripple dark class="green" width="180">
          <v-card-subtitle class="pb-0">Pending Services</v-card-subtitle>
          <!-- <v-card-title class="pt-0 text-h4">{{ newDocCount?newDocCount:0 }}</v-card-title> -->
        </v-card>
      </v-row>
    <v-row no-gutters>
      <v-text-field v-model="search" outlined rounded label="Search Service..."></v-text-field>
    </v-row>
    <v-card outlined>
      <v-data-table
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="siteAds">
        <template v-slot:item.desc="{ item }">
          <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.desc">
            View
          </v-btn>
        </template>

        <template v-slot:item.image_path="{ item }">
          <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.image_path">
            View
          </v-btn>
        </template>

        <template v-slot:item.ad_url="{ item }">
          <v-btn outlined rounded target="_blank" :href="item.ad_url">
            Visit
          </v-btn>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            class="ma-2"
            :color=" item.status === '1' ? 'success': 'red'"
          >
          {{ item.status === "1" ? 'Approved' : item.status === "3" ? 'Rejected' : '' }}
          </v-chip>
        </template>

        <template v-slot:item.description="{ item }">
          <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
            View
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
            <v-btn icon @click="editProduct(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-icon @click="showDeleteConfirmation(item, deleteItem)" color="error">mdi-delete</v-icon>
          </template>
        

      </v-data-table>
    </v-card>

    <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
      <AddSiteAd :site_ads='site_ads' :domain="domain" :orderList="orderList" :edit="isEdit" @close="closeDialogs"/>
    </v-dialog>

    <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <span class="headline">Description</span>
            <v-spacer/>
            <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider class="mb-4"/>
        <v-card-text>
          {{ selectedDescription }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet persistent="false" scrollable  v-if="addNew" v-model="addNew" inset hide-overlay >
        <v-card class="rounded-t-lg rounded-b-0">
          <v-card-title>
            <v-row no-gutters>
              {{ isEdit ? 'Edit' : 'Add' }} Service
              <v-spacer/>
              <v-btn color="red" icon @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
  
          <v-card-text>
            <v-form v-if="!isEdit" ref="form" v-model="valid">
              <v-text-field v-model="data.name" outlined label="Name" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="data.description" outlined label="Description" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
              <v-file-input v-model="image" prepend-icon="" prepend-inner-icon="mdi-image" outlined label="Image" :rules="[rules.required]"></v-file-input>
            </v-form>
  
            <v-form v-if="isEdit" ref="formEdit" v-model="validEdit">
              <v-text-field v-model="data.name" outlined label="Name" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="data.description" outlined label="Description" :rules="[rules.required]"></v-text-field>
              <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
              <v-file-input v-model="image" prepend-icon="" prepend-inner-icon="mdi-image" outlined label="Image"></v-file-input>
            </v-form>
          </v-card-text>
  
          <v-card-actions style="background: #1976d2">
            <v-spacer/>
            <v-btn v-if="isEdit" :loading="loading" @click="saveEdited" large color="white">
              <v-icon class="mr-2">mdi-check</v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import AddSiteAd from "@/components/AddSiteAd.vue";

export default {
  name: "ApproveService",
  components: {AddSiteAd},
  data: () => ({
    rules: {
        required: value => !!value || 'Required.',
      },
    data: {
        id: null,
        name: '',
        description: '',
        price: 0,
        image_path: '',
    },
    loading: true,
    domain:'',
    orderList:[],
    addStoryDialog: false,
    descriptionViewDialog:false,
    optionalViewDialog:false,
    selectedOptional: null,
    selectedDescription: null,
    valid: false,
    validEdit: false,
    image: null,
    isEdit: false,
    site_ads: null,
    addNew: false,
    isEdit: false,
    siteAds: [],
    headers: [
      {text: "ID", value: "id"},
      {text: "Service Name", value: "name"},
      {text: "Gym Name", value: "gym_name"},
      {text: "price", value: "price"},
      {text: "Image", value: "image_path"},
      {text: "Description", value: "description"},
      {text: "Status", value: "status"},
      {text: "Actions", value: "actions"},
    ],
    search: '',
  }),
  methods: {
    statusChange(id, status) {
      authClient.post('/status/change',{
          status: status,
          tableId: 6,
          resultId: id
        }).then((response) => {
        let data = response.data[0]
        if(data.status){
          this.$toast.success({
            title: 'Ok',
            message: data.message,
        });
        this.getSiteAds()
        }else{
          this.loading = false;
          this.$toast.error({
            title: 'error',
            message: data.message,
        });
        }
        }).catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
          authClient.post('gym-services/delete',{
            service_id: item.id
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getSiteAds()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.ad_img
            }).then((response) => {
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
    getSiteAds() {
      authClient.get('/gym-services/pending/approved-list').then((response) => {
        let data = response.data[0]
        if(data.status){
          console.log(data.data.result);
          this.siteAds = data.data.result;
          this.domain = data.data.domain;
        }else{
          this.loading = false;
          this.$toast.error({
            title: 'error',
            message: data.message,
        });
        }
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    resetProduct(){
          this.data = {
            id: null,
            name: '',
            description: '',
            code: '',
            price: 0,
            quantity: 0,
            image_path: '',
            CategoryId: null,
          };
          this.image = null;
    },
    close() {
          this.addNew = false;
          this.getSiteAds();
          this.resetProduct();
        },
    editProduct(item){
      console.log(item);
          this.isEdit = true;
          this.addNew = true;
          this.data = item;
    },
    saveEdited(){
          this.$refs.formEdit.validate();
          if (this.validEdit) {
            this.loading = true;
            if(this.image === null){
              authClient.post('/gym-services/update', this.data).then(res => {
                console.log(res);
                this.loading = false;
                this.close();
              }).catch(err => {
                this.loading = false;
                console.log(err);
                window.alert('Something went wrong. Please try again later.');
              });
            } else {
              authClient.post('/users/file-uploads/delete-file', {
                filePath: this.data.image_path
              }).then(rs => {
                console.log(rs);
                let formData = new FormData();
                formData.append('image', this.image);
                authClient.post('/users/file-uploads/gymService', formData).then(res => {
                  if(res.status === 200){
                    let mData = this.data;
                    console.log(res.data);
                    mData.image_path = res.data.image_path;
                    console.log(mData);
                    authClient.post('/gym-services/update',mData).then(res2 => {
                      if(res2.status === 200){
                        this.loading = false;
                        this.close();
                      }
                    }).catch(err => {
                      this.loading = false;
                      console.log(err);
                      window.alert('Something went wrong. Please try again later.');
                    });
                  }
                }).catch(err => {
                  this.loading = false;
                  window.alert('Error uploading image');
                });
              });
  
  
            }
  
  
          }
        },
  },
  mounted() {
    this.getSiteAds();
  }
}
</script>

<style scoped>

</style>
