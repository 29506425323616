<template>
    <v-container>
      <v-row no-gutters align="center">
        <span class="text-h5">Manage Tax Master</span>
        <v-spacer/>
        <!-- <v-btn large rounded elevation="0" color="black" dark @click="upsertASubscription=true">Add A Plan</v-btn> -->
      </v-row>
      <v-divider class="my-4"/>
  
      <v-row no-gutters>
        <v-card class="ma-2 rounded-lg" @click="edit(plan)" width="40%" hover ripple v-for="(plan,i) in plans" :key="i">
          <v-card-title>
             Discount Type: {{ plan.tax_type == 1? 'Percentage' : 'Flat Rate' }}
          </v-card-title>
          <v-card-subtitle>Discount: {{ plan.tax_type == 1 ? `${plan.percentage} %` : `${plan.flat_rate} MVR` }}</v-card-subtitle>
          
        </v-card>
      </v-row>
  
      <v-bottom-sheet scrollable inset persistent v-if="upsertASubscription" v-model="upsertASubscription">
        <UpsertTaxMaster :plan="editing" @close="close"/>
      </v-bottom-sheet>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import UpsertTaxMaster from "@/components/UpsertTaxMaster";
  
  export default {
    name: "TaxMasterManage",
    components: {UpsertTaxMaster},
    data: ()=>({
      search: '',
      plans: [],
      loading: true,
      upsertASubscription: false,
      editing: null
    }),
    methods: {
      getDiscountedPrice(plan){
        if(plan.discounted){
          return (plan.price - (plan.price * (plan.discounted_percentage/100))).toFixed(2);
        } else {
          return plan.price.toFixed(2);
        }
      },
      edit(plan){
        console.log(plan);
        this.editing = plan;
        this.upsertASubscription = true;
      },
      close(){
        this.upsertASubscription = false;
        this.editing = null;
        this.getPlans();
      },
      getPlans(){
        this.loading = true;
        authClient.get('/tax-master/actions/list').then(res=>{
          this.plans = res.data[0] == null ? [] : res.data ;
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
      },
    },
    mounted() {
      this.getPlans();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  