<template>
  <v-card class="rounded-t-lg rounded-b-0">
    <v-card-title>
      <v-row no-gutters>
           Update Body Fat
        <v-spacer/>
        <v-btn color="red" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="sm-6">
            <v-text-field v-model="frontUpperArm" type="number" placeholder="Front of Upper Arm (mm)" :rules="[rules.required]" outlined label="Front of Upper Arm (mm)"/>
            <v-text-field v-model="sideWaist" type="number" placeholder="Side of Waist (mm)" :rules="[rules.required]" outlined label="Side of Waist (mm)"/>
          </v-col>
          <v-col cols="sm-6">
            <v-text-field v-model="backUpperArm" type="number" placeholder="Back of Upper Arm (mm)" :rules="[rules.required]" outlined label="Back of Upper Arm (mm)"/>
            <v-text-field v-model="backBelowShoulder" type="number" placeholder="Back Of Below Shoulder (mm)" :rules="[rules.required]" outlined label="Back Of Below Shoulder (mm)"/>
          </v-col>
        </v-row>
        <v-text-field v-model="userWeight" type="number" placeholder="Weight (kg)" :rules="[rules.required]" outlined label="Weight (kg)"/>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn @click="upsertPlan" dark large color="primary">
        <v-icon class="mr-2">mdi-check</v-icon>
        Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "UpdateBodyFat",
  data: () => ({
    rules: {
    required: value => !!value || 'Required.',
    },
    frontUpperArm:"",
    backUpperArm:"",
    sideWaist:"",
    backBelowShoulder:"",
    userWeight:""
  ,
  valid:false,
    editSheet: false,
    userWeight:"",
    userHeight:"",
  }),
  methods:{
    close(){
      this.$emit('close')
    },
    upsertPlan(){
    this.$refs.form.validate();
    if(this.valid){
      this.loading = true;

      let age = Math.floor((new Date() - new Date(this.userData.birthday)) / (365.25 * 24 * 60 * 60 * 1000));

      authClient.post(`/fitness/gym/${this.userData.id}/fat-data`, 
          {
            "front_upper_arm": this.frontUpperArm, 
            "back_upper_arm":this.backUpperArm,
            "side_of_waist": this.sideWaist, 
            "back_below_shoulder":this.backBelowShoulder,
            "age": age, 
            "gender": this.userData.gender, 
            "weight":this.userWeight
          }
        ).then(res=>{
        let response_data = res.data
            if(response_data.success){
              this.close();
              this.$toast.success({
                title: 'Ok',
                message: 'success',
            });
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: response_data.error,
            });
            }
      }).catch(err=>{
        console.log(err);
      }).finally(()=>{
        this.loading = false;
      })
    }
  },
  },
  mounted() {
    this.frontUpperArm = this.fatData.front_upper_arm, 
    this.backUpperArm = this.fatData.back_upper_arm,
    this.sideWaist = this.fatData.side_of_waist, 
    this.backBelowShoulder = this.fatData.back_below_shoulder, 
    this.userWeight = this.fatData.weight
  },
  props: {
    userData: {
      required: true
    },
    fatData: {
        required: true
      },
  },
}
</script>

<style scoped>

</style>
