<template>
  <v-container>
    <v-row no-gutters class="mb-4">
      <v-card to="/doctors/new-doctors" outlined hover ripple dark class="green mr-4" width="180">
        <v-card-subtitle class="pb-0">New Doctors</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ newDocCount }}</v-card-title>
      </v-card>
      <v-card outlined hover ripple dark class="warning  mr-4" width="180">
        <v-card-subtitle class="pb-0">All Doctors</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ doctorCount  }}</v-card-title>
      </v-card>
      <v-card to="/doctor-revenue" outlined hover ripple dark class="warning" min-width="180" width="auto">
        <v-card-subtitle class="pb-0">Doctors Revenue</v-card-subtitle>
        <v-card-title class="pt-0 text-h4"> {{ amount_total }}</v-card-title>
      </v-card>
    </v-row>
    
    <v-row gutters>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <div>
      <v-text-field v-model="search" outlined class="rounded-lg" label="Search Doctors..." append-icon="mdi-magnify" @keyup.enter="getDoctors()" @click:append="getDoctors()"></v-text-field>
      </div>
          </v-col>
          <v-col
                class="pb-0"
                cols="12"
                md="6"
                lg="4"
          >
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="rounded-lg"
                  outlined
                  readonly
                  v-model="dateRangeText"
                  label="Date Range"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="dates= []"
                >
                  Reset
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(date);getDoctors()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
                cols="12"
                md="3"
                lg="2"
          >
                <v-btn
                @click="getDoctors(1)"
                :style="{ width: '100%' }"
                  x-large
                  color="warning"
                  dark
                :loading="loading"
                >
                <v-icon
                  left
                  dark
                >
                mdi-tray-arrow-down
                </v-icon>
                  Export Pdf
                </v-btn>
          </v-col>
          <v-col
                cols="12"
                md="3"
                lg="2"
          >
                <v-btn
                @click="downloadCsv()"
                :loading="loading"
                :style="{ width: '100%' }"
                  x-large
                  color="warning"
                  dark
                >
                <v-icon
                  left
                  dark
                >
                mdi-tray-arrow-down
                </v-icon>
                  Export Csv
                </v-btn>
          </v-col>
        </v-row>
        <v-row gutters class="mt-0">
          <v-col
                class="pb-0"
                cols="12"
                md="6"
                lg="4"
          >
          <v-select @change="getDoctors()" :items="statusTypes" v-model="onlineStatus" class="rounded-lg" item-text="text" item-value="value"  outlined label="Online Status"/>
        </v-col>
      </v-row>



    <v-card outlined :loading="loading">
      <v-data-table
          :headers="headers"
          :items="doctors">
        <template v-slot:item.status="{ item }">
          <v-chip dark :color="item.doctor.approved ? 'green':'red'">{{ item.doctor.approved ? 'Approved':'Suspended' }}</v-chip>
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     v-on="on" v-if="!item.doctor.approved" @click="toggleStatus(item)" color="green" icon>
                <v-icon>mdi-check</v-icon>
              </v-btn>

            </template>
            <span>Approve</span>
          </v-tooltip>


          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     v-on="on" @click="toggleStatus(item)" color="red" v-if="item.doctor.approved" icon>
                <v-icon>mdi-pause-circle-outline</v-icon>
              </v-btn>

            </template>
              <span>De-Approve</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="viewDetails(item)" v-bind="attrs"
                     v-on="on" icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>

            </template>
            <span>More Info</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="viewDetails_revenue(item)" v-bind="attrs"
                     v-on="on" icon>
                <v-icon>mdi-file-account-outline</v-icon>
              </v-btn>

            </template>
            <span>Revenue</span>
          </v-tooltip>
        </template>

        <template v-slot:item.doctor.can_prescribe="{ item }">
          <v-chip dark :color="item.doctor.can_prescribe ? 'green':'red'">{{ item.doctor.can_prescribe ? 'YES':'NO' }}</v-chip>
        </template>
      </v-data-table>
    </v-card>


  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import Papa from 'papaparse';

export default {
  name: "Doctors",
  data: () => ({
    loading: true,
    doctorCount:0,
    date:null,
    menu: false,
    modal: false,
    menu2: false,
    amount_total:0,
    dates:[],
    onlineStatus:'0',
    statusTypes: [
        {text: 'All', value: '0'},
        {text: 'Online', value: '1'},
        {text: 'Offline', value: '2'}
    ],
    headers: [
      {text: "ID", value: "id"},
      {text: "Title", value: "doctor.title"},
      {text: "Name", value: "name"},
      {text: "Specialty", value: "doctor.speciality"},
      {text: "Charge Type", value:'doctor.charge_type'},
      {text: "Currency", value:'currency'},
      {text: "Country", value:'country_code'},
      {text: "Can Prescribe", value:'doctor.can_prescribe'},
      {text: "Status", value: "status"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    search: '',
    newDocCount: 0,
    doctors:[],
  }),
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
  },
  methods: {
    getDoctors(type=0) {
      this.loading = true;
      authClient.post('/doctors/all-old',{
        'dateRange':this.dates,
        'onlineStatus' : this.onlineStatus,
        'search' : this.search,
        'type' : type,
      }, {responseType:  type == 0? '':'blob'}).then(response => {

        if(type == '1'){

          const href = window.URL.createObjectURL(response.data);

          const anchorElement = document.createElement('a');

          anchorElement.href = href;
          anchorElement.download = `${Date.now()}_doctor_insights.pdf`;
          document.body.appendChild(anchorElement);
          anchorElement.click();

          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);

        }else{
          this.doctors = response.data;
         
        }
        this.loading = false;
      });
    },
    downloadCsv() {
      // Replace this with your actual CSV data
      const csvData = this.doctors.map(item => ({
        UserId: item.id,
        RegDate: item.created_at,
        Name: item.name,
        Nationality: item.country_code,
        Gender: item.gender,
        Email: item.email,
        Phone: item.phone,
        ChargeType: item.doctor.charge_type,
        }));;

        const csv = Papa.unparse(csvData);

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${Date.now()}_trainer_insights.csv`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
    viewDetails(item){
      console.log(item);
      this.$router.push({name:'Medical Professionals Profile Overview', params:{member:item}});
    },
    viewDetails_revenue(item){
      this.$router.push({name:'Single Medical Professionals', params:{member:item}});
    },
    toggleStatus(item){
      let result = window.prompt('Are you sure you want to toggle the status of this doctor?. Type "YES" to confirm');
      if (result === 'YES') {
        this.loading = true;
        authClient.post('/doctors/toggle-status', {
          doctor_id: item.doctor.user_id,
        })
          .then(response => {
            console.log(response);
            this.getDoctors();
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    // getDoctors() {
    //   this.loading = true;
    //   authClient.get('/doctors/all-old').then(response => {
    //     this.doctors = response.data;
    //     this.loading = false;
    //     console.log(this.doctors);
    //   }).catch(error => {
    //     console.log(error);
    //     this.loading = false;
    //   });
    // },
    getNewDoctors() {
      this.loading = true;
      authClient.get('/doctors/all-new').then(response => {
        this.newDocCount = response.data.length;
        this.loading = false;
        console.log('NEW');
        console.log(response.data);
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
    getCounts() {
      this.loading = true;
      authClient.get('/dashboard/counts').then(response => {
        this.doctorCount = response.data.doctors_count;
        this.loading = false;
        console.log('NEW');
        console.log(response.data);
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
    getRevenueCounts() {
        this.loading = true;
        authClient.get('doctors/counts').then(response => {
          this.amount_total = response.data.amount_total;
          this.loading = false;
        }).catch(error => {
          console.log(error);
          this.loading = false;
        });
      },
  },
  mounted() {
    this.getDoctors();
    this.getRevenueCounts()
    this.getNewDoctors();
    this.getCounts()
  }
}
</script>

<style scoped>

</style>
