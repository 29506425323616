import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Manage Tax Master")]),_c(VSpacer)],1),_c(VDivider,{staticClass:"my-4"}),_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.plans),function(plan,i){return _c(VCard,{key:i,staticClass:"ma-2 rounded-lg",attrs:{"width":"40%","hover":"","ripple":""},on:{"click":function($event){return _vm.edit(plan)}}},[_c(VCardTitle,[_vm._v(" Discount Type: "+_vm._s(plan.tax_type == 1? 'Percentage' : 'Flat Rate')+" ")]),_c(VCardSubtitle,[_vm._v("Discount: "+_vm._s(plan.tax_type == 1 ? `${plan.percentage} %` : `${plan.flat_rate} MVR`))])],1)}),1),(_vm.upsertASubscription)?_c(VBottomSheet,{attrs:{"scrollable":"","inset":"","persistent":""},model:{value:(_vm.upsertASubscription),callback:function ($$v) {_vm.upsertASubscription=$$v},expression:"upsertASubscription"}},[_c('UpsertTaxMaster',{attrs:{"plan":_vm.editing},on:{"close":_vm.close}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }