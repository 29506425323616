import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCol,[_c('UserDetailsHeading',{staticClass:"mb-4",attrs:{"data":_vm.member}}),_c(VDivider,{staticClass:"mb-4"}),_c(VRow,{staticClass:"mt-8",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('UserBaseDetails',{attrs:{"details":_vm.getDetails()}}),_c(VCardTitle,{staticClass:"pl-0"},[_vm._v("Qualifications")]),_vm._l((_vm.member.qualifications),function(qx,i){return _c(VCard,{key:i,staticClass:"ma-2 rounded-lg",attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(qx.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(qx.description)+" ")])],1)})],2),_c(VCol,{attrs:{"cols":"8"}},[_c('UserEWalletTransactions',{attrs:{"user_id":_vm.member.id}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }