import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[[_c(VRow,{staticClass:"pt-15",attrs:{"justify":"center"}},[_c(VExpansionPanels,{attrs:{"popout":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,{attrs:{"active":""}},[_c(VExpansionPanelHeader,[_vm._v("No Subscription")]),_c(VExpansionPanelContent,[_c(VRow,_vm._l((_vm.selectedWidget),function(item,index){return _c(VCol,{attrs:{"sm":"6"}},[_c(VCheckbox,{key:item.id,attrs:{"label":item.name},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)}),1),_c(VBtn,{attrs:{"large":"","color":"white"},on:{"click":function($event){return _vm.upsertPlan('1')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v("Free Trial")]),_c(VExpansionPanelContent,[_c(VRow,_vm._l((_vm.selectedFreeWidget),function(item,index){return _c(VCol,{attrs:{"sm":"6"}},[_c(VCheckbox,{key:item.id,attrs:{"label":item.name},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)}),1),_c(VBtn,{attrs:{"large":"","color":"white"},on:{"click":function($event){return _vm.upsertPlan('2')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v("Pro User")]),_c(VExpansionPanelContent,[_c(VRow,_vm._l((_vm.selectedProWidget),function(item,index){return _c(VCol,{attrs:{"sm":"6"}},[_c(VCheckbox,{key:item.id,attrs:{"label":item.name},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)}),1),_c(VBtn,{attrs:{"large":"","color":"white"},on:{"click":function($event){return _vm.upsertPlan('3')}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }