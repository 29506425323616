<template>
    <v-container>
      <v-row  class="mb-4 my-1">
        <v-card outlined hover ripple dark class="warning mr-4 mb-3 mb-md-0" min-width="180" width="auto">
        <v-card-subtitle class="pb-0">Total Amount</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ Total_amount.toFixed(2) }}</v-card-title>
      </v-card>
      </v-row>
      <v-row gutters>
        <v-col
              class="pb-0"
              cols="12"
              md="6"
              lg="4"
        >
        <div>
      <v-text-field v-model="search" outlined class="rounded-lg" label="Search Members..." append-icon="mdi-magnify" @keyup.enter="getMembers()" @click:append="getMembers()"></v-text-field>
    </div>
        </v-col>
        <v-col
              class="pb-0"
              cols="12"
              md="6"
              lg="4"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="rounded-lg"
                outlined
                readonly
                v-model="dateRangeText"
                label="Date Range"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dates= []"
              >
                Reset
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date);getMembers()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
              cols="12"
              md="3"
              lg="2"
        >
              <v-btn
              @click="getMembers(1)"
              :style="{ width: '100%' }"
                x-large
                color="warning"
                dark
              :loading="loading"
              >
              <v-icon
                left
                dark
              >
              mdi-tray-arrow-down
              </v-icon>
                Export Pdf
              </v-btn>
        </v-col>
        <v-col
              cols="12"
              md="3"
              lg="2"
        >
              <v-btn
              @click="downloadCsv()"
              :loading="loading"
              :style="{ width: '100%' }"
                x-large
                color="warning"
                dark
              >
              <v-icon
                left
                dark
              >
              mdi-tray-arrow-down
              </v-icon>
                Export Csv
              </v-btn>
        </v-col>
      </v-row>
  
      <v-card outlined :loading="loading" class="mt-5">
        <v-data-table
            id="dataTabel"
            :headers="headers"
            :items="members">
          <template v-slot:item.membership="{ item }">
            <v-chip dark :color="hasActiveSub(item) ? '#ffa200':''">{{ hasActiveSub(item) ? 'PRO' : 'Member' }}</v-chip>
          </template>
  
          <template v-slot:item.user.avatar_url="{ item }">
            <v-avatar class="ma-2">
              <v-img :src="$store.state.s3BaseUrl + item.user.avatar_url"></v-img>
            </v-avatar>
          </template>
  
          <template v-slot:item.user.status="{ item }">
              <v-chip
                class="ma-2"
                :color=" item.user.status == 1 ? 'success': 'warning'"
              >
              {{ item.user.status == 1 ? 'Active' : 'Disabled' }}
              </v-chip>
            </template>
  
          <template v-slot:item.subscription="{ item }">
               {{  ValidDate(item)  }}
          </template>
  
          <template v-slot:item.created_at="{ item }">
              {{  new Date(item.created_at).toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  timeZone: 'UTC'
                  })
                }}
          </template>
  
          <template v-slot:item.actions="{ item }">
  
            <v-btn @click="statusChange(item.user.id, item.user.status == 1? 2 : 1)" icon>
              <v-icon :color=" item.user.status == 2 ? 'success': 'warning'"> {{ item.user.status == 1 ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
            </v-btn>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="viewDetails(item)" v-bind="attrs"
                       v-on="on" icon>
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
  
              </template>
              <span>More Info</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
  
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import Papa from 'papaparse';
  
  export default {
    name: "SingleComgymSummery",
    props: {
        gym_id: Number
    },
    data: () => ({
      search: '',
      members: [],
      date:null,
      menu: false,
      Total_amount:0,
      modal: false,
      menu2: false,
      memberShip:'0',
      gym_id: '',
      dates: [],
      loading: true,
      proMemberCount:0,
      MemberCount:0,
      active_member_count:0,
      disabled_member_count:0,
      headers: [
        {text: "Transaction Date & Time", value: "created_at"},
        {text: "Booked By", value: "trainer.name"},
        {text: "Gym Name", value: "gym.gym_name"},
        {text: "Amount", value: 'total_amount'}
      ],
    }),
    computed: {
        dateRangeText () {
          return this.dates.join(' ~ ')
        },
      },
    methods: {
      viewDetails(item) {
        this.$router.push({name: 'Member Profile Overview', params: {member: item}});
      },
      hasActiveSub(item) {
        if (item.subscription === null) {
          return false;
        } else {
          return item.subscription.is_active
        }
      },
      downloadCsv() {
        // Replace this with your actual CSV data
        const csvData = this.members.map(item => ({
          "Transaction Date & Time": item.created_at,
          "Gym Name": item.gym.gym_name,
          "Bookded By": item.trainer.name,
          "Amount": item.total_amount
        }));;
  
        const csv = Papa.unparse(csvData);
  
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
  
        // Create a temporary anchor element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${Date.now()}SingleExclusiveGymSummery.csv`;
        document.body.appendChild(a);
        a.click();
  
        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      ValidDate(item) {
        if (item.subscription === null) {
          return 'N/A';
        } else {
  
          var options = { 
            year: 'numeric', 
            month: 'short', 
            day: 'numeric' 
          };
  
          if(item.subscription.is_active){
            const currentDate = new Date();
            const newDate = new Date(item.subscription.valid_till);
            const differenceMs = newDate - currentDate;
            const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
            return `${newDate.toLocaleDateString('en-GB', options)} (${daysDifference} days left)`;
          }else{
            return 'Expired'
          }
        }
      },
      statusChange(id, status) {
          authClient.post('/status/change',{
              status: status,
              tableId: 1,
              resultId: id
            }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
            });
            this.getMembers()
            this.getCounts()
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
            }
            }).catch((error) => {
              console.log(error);
            });
        },
      getMembers(type=0) {
        this.loading = true;
        authClient.post('/commercial-gym/summery',{
          'dateRange':this.dates,
          'gym_id' : this.gym_id,
          'search' : this.search,
          'type' : type,
        }, {responseType:  type == 0? '':'blob'}).then(response => {
  
          if(type == '1'){
  
            const href = window.URL.createObjectURL(response.data);
  
            const anchorElement = document.createElement('a');
  
            anchorElement.href = href;
            anchorElement.download = `${Date.now()}SingleExclusiveGymSummery.pdf`;
            document.body.appendChild(anchorElement);
            anchorElement.click();
  
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
  
          }else{
            this.members = response.data;
           
          }
          this.loading = false;
        });
      },
      getCounts() {
        this.loading = true;
        authClient.post('/commercial-gym/summery/counts', {gym_id:this.gym_id}).then(response => {
          this.Total_amount = response.data.total;
          this.loading = false;
          console.log('NEW');
          console.log(response.data);
        }).catch(error => {
          console.log(error);
          this.loading = false;
        });
      },
    },
    mounted() {
      this.getMembers();
      this.getCounts()
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  