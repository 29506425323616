import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"rounded-t-lg rounded-b-0"},[_c(VCardTitle,[_c(VRow,{attrs:{"no-gutters":""}},[_vm._v(" Edit Basic Details "),_c(VSpacer),_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardText,[_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","label":"Name"},model:{value:(_vm.editData.name),callback:function ($$v) {_vm.$set(_vm.editData, "name", $$v)},expression:"editData.name"}}),_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","label":"Email"},model:{value:(_vm.editData.email),callback:function ($$v) {_vm.$set(_vm.editData, "email", $$v)},expression:"editData.email"}}),_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","label":"Phone"},model:{value:(_vm.editData.phone),callback:function ($$v) {_vm.$set(_vm.editData, "phone", $$v)},expression:"editData.phone"}}),_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","label":"Birthday"},model:{value:(_vm.editData.birthday),callback:function ($$v) {_vm.$set(_vm.editData, "birthday", $$v)},expression:"editData.birthday"}}),_c(VSelect,{staticClass:"rounded-lg",attrs:{"items":_vm.gender,"outlined":"","label":"Gender"},model:{value:(_vm.editData.gender),callback:function ($$v) {_vm.$set(_vm.editData, "gender", $$v)},expression:"editData.gender"}}),_c(VTextField,{staticClass:"rounded-lg",attrs:{"outlined":"","label":"NIC"},model:{value:(_vm.editData.nic),callback:function ($$v) {_vm.$set(_vm.editData, "nic", $$v)},expression:"editData.nic"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","large":"","color":"primary"},on:{"click":_vm.editUser}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }