import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"mb-4"},[_c(VCardTitle,[_vm._v("Add Exercise")]),_c(VCardText,[_c(VRow,{staticClass:"mb-n4"},[_c(VCol,{attrs:{"cols":"12","sm":"9"}},[_c(VSelect,{attrs:{"item-value":"id","item-text":"title","items":_vm.exercises,"outlined":"","label":"Select Exercise"},model:{value:(_vm.selectedExcercise),callback:function ($$v) {_vm.selectedExcercise=$$v},expression:"selectedExcercise"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VSelect,{attrs:{"items":_vm.days,"outlined":"","label":"Select Day"},model:{value:(_vm.selected_date),callback:function ($$v) {_vm.selected_date=$$v},expression:"selected_date"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"type":"number","label":"Reps"},model:{value:(_vm.repetitions),callback:function ($$v) {_vm.repetitions=$$v},expression:"repetitions"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"type":"number","label":"Weight (kg)"},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"type":"number","label":"Sets"},model:{value:(_vm.sets),callback:function ($$v) {_vm.sets=$$v},expression:"sets"}})],1)],1),_c(VTextarea,{staticClass:"mx-2",attrs:{"label":"Notes","rows":"2"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c(VBtn,{on:{"click":_vm.addField}},[_vm._v("Add Field")])],1)],1),_vm._l((_vm.fields),function(field,index){return _c(VCard,{key:index,staticClass:"mb-4"},[_c(VCardTitle,[_vm._v(" "+_vm._s(field.title)+" "),_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _vm.editField(index)}}},[_vm._v("Edit")]),_c(VBtn,{on:{"click":function($event){return _vm.deleteField(index)}}},[_vm._v("Delete")])],1),_c(VCardText,[_vm._v(" "+_vm._s(field.description)+" ")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }