import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Basic Details")]),_c(VCardText,[_c(VCol,{staticClass:"pa-0"},_vm._l((_vm.details),function(detail,i){return _c(VRow,{key:i,staticClass:"mb-4",attrs:{"no-gutters":""}},[_c(VCol,[_vm._v(_vm._s(detail.name))]),_c(VCol,{staticClass:"font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(_vm._s(detail.value))])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }