<template>
    <v-container>
      <v-card outlined elevation="0">
        <v-card-title class="d-flex justify-space-between">
          <template>
          <span class="ml-2"><v-icon>mdi-wallet</v-icon>Service Subscriptions</span>
          </template>
          <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="rounded-lg sm"
              outlined
              readonly
              v-model="dateRangeText"
              label="Date Range"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dates= []"
            >
              Reset
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date);getTransactions()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        </v-col>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="transactions"
            :loading="loading"
            :items-per-page="10"
            class="elevation-1">
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at.split('T')[0] }}
          </template>
          <template v-slot:item.type="{ item }">
            <v-chip dark :color="item.type === 'Credit' ? 'green' : 'red'">{{ item.type }}</v-chip>
          </template>
          <template v-slot:item.clients="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.clients">
              View
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Member Info</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
            <GymSubscribedClients :members="selectedDescription"/>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import TopupDialog from "@/components/TopupDialog";
  import GymSubscribedClients from "@/components/GymSubscribedClients";
  
  
  export default {
    components: {TopupDialog,GymSubscribedClients},
    name: "exclusiveGymServiceSubscription",
    props:{
      user_id:{
        required: true
      },
      isNotTopup:{
        required: false
      }
    },
    data: () => ({
      date:null,
      selectedDescription:[],
      descriptionViewDialog:false,
      dates: [],
      menu: false,
      modal: false,
      menu2: false,
      isShowTopup: true,
      topupDialog: false,
      transactions: [],
      loading: true,
      headers: [
        { text: 'Service', value: 'service_data.name' },
        { text: 'Trianer', value: 'trainer.email' },
        { text: 'Start Time', value: 'start_time' },
        { text: 'Members', value: 'clients' },
      ],
    }),
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods:{
      getTransactions(){
        this.loading = true;
        authClient.post('/fitness/exclusive-gyms/service/subscriptions',{
          gymId:this.user_id,
          'dateRange':this.dates
        }).then(value => {
          this.transactions = value.data;
          console.log(value.data);
          this.loading = false;
        });
      },
      close(){
        this.getTransactions()
        this.topupDialog = false;
        this.$emit('close');
      }
    },
    mounted() {
      this.dates = [new Date().toISOString().split('T')[0]]
      this.getTransactions();
      
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  