import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_vm._v(" Add a Image "),_c(VSpacer),_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VFileInput,{staticClass:"mr-1",attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-paperclip","label":"Image","outlined":""},model:{value:(_vm.current_file_img_1),callback:function ($$v) {_vm.current_file_img_1=$$v},expression:"current_file_img_1"}}),_c(VRow,{attrs:{"no-gutters":""}},[_c(VSelect,{attrs:{"items":_vm.notificationTypes,"item-text":"text","item-value":"value","rules":[_vm.rules.required],"outlined":"","label":"Select The Type"},model:{value:(_vm.data.notification_type),callback:function ($$v) {_vm.$set(_vm.data, "notification_type", $$v)},expression:"data.notification_type"}})],1)],1)],1),_c(VCardActions,{staticStyle:{"background":"#1976d2"}},[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"large":"","color":"white"},on:{"click":_vm.saveOrUpdate}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }