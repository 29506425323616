<template>
    <v-card>
      <v-card-title>
        {{ this.plan !== null ? 'Edit' : 'Add' }} a Plan
        <v-spacer/>
        <v-btn color="red" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
  
      <v-card-text>
        <v-form ref="form" v-model="valid" class="mb-4">
          <template class="mt-4">
            <v-select :items="discount_types" item-text="text" item-value="value" v-model="data.discount_type" :rules="[rules.required]" outlined label="Discount Type" class="pl-2 pt-2"/>
            <v-text-field v-if="data.discount_type == 1" append-icon="mdi-percent" type="number" min="0" max="100" v-model="data.discounted_percentage" :rules="[rules.required]" outlined label="Discounted Percentage" class="pl-2"/>
            <v-text-field v-if="data.discount_type == 2"  type="number" min="0" v-model="data.flat_rate" :rules="[rules.required]" outlined label="Flat Rate" class="pl-2"/>
          </template>
          
  
        </v-form>
      </v-card-text>
  
  
      <v-card-actions style="background: #1976d2">
        <v-spacer/>
        <v-btn @click="showDeleteConfirmation(upsertPlan)" large color="white">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "UpsertTaxMaster",
    data:()=>({
      loading:false,
      rules: {
        required: value => !!value || 'Required.',
      },
      durationUnits: [
        {text: 'Months', value: 'month'},
        {text: 'Years', value: 'year'},
        {text: 'Lifetime', value: 'lifetime'},
      ],
      discount_types: [
        {text: 'Percentage', value: '1'},
        {text: 'Flat Rate', value: '2'},
      ],
      data: {
        flat_rate:null,
        discounted_percentage:null,
        discount_type:'1',
      },
      valid: false,
    }),
    methods:{
      showDeleteConfirmation(callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'If tax component change this will apply to all transactions and reports of finance',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback()
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      upsertPlan(){
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;
          authClient.post('/tax-master/actions/upsert', this.data).then(res=>{
            let data = res.data[0]
            console.log(data)
            if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
         
          console.log(res);
            this.$emit('close');
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
            

          }).catch(err=>{
            console.log(err);
          }).finally(()=>{
            this.loading = false;
          })
        }
      },
  
      close(){
        this.$emit('close');
      }
    },
    mounted() {
      console.log(this.plan);
      if(this.plan !== null){
        this.data.flat_rate = this.plan.flat_rate;
        this.data.discount_type = this.plan.tax_type
        this.data.discounted_percentage = this.plan.percentage
        console.log(this.data)
      }
    },
    props:{
      plan:{
        type:Object,
        default:null
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  