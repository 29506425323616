<template>
  <v-container>

    <v-row  class="mb-4 my-1"  no-gutters>
      <v-card outlined hover ripple dark class="green mr-4 mb-3 mb-md-0" width="200" @click="openGymSummary()">
        <v-card-title class="pb-0 d-flex align-items-center">
          <div>View summary</div>
           <div>
            <span class="mdi mdi-arrow-right-circle-outline ml-2"></span>
           </div>
          </v-card-title>
        <v-card-title class="pt-0 text-h4">{{ MemberCount }}</v-card-title>
      </v-card>
      <v-spacer></v-spacer>
      <v-btn @click="addNew = true" rounded dark color="black" height="56" elevation="0" class="ml-2">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add New
      </v-btn>  
    </v-row>

    <v-row no-gutters>
      <v-text-field hint="Press ENTER to Search" @change="getGyms()" v-model="search" outlined rounded label="Search Commercial Gyms..."></v-text-field>
      
    </v-row>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-row no-gutters justify="start">
      <v-card  width="300" class="ma-2 rounded-lg" hover ripple v-for="(gym,i) in gyms" :key="i">
        <v-card-title @click="openGymDetails(gym)" class="mb-0 pb-0">
          <v-spacer/>
          <v-avatar size="80">
            <v-img :src="$store.state.s3BaseUrl + gym.user.avatar_url"></v-img>
          </v-avatar>
        </v-card-title>
        <v-card-title @click="openGymDetails(gym)" class="pt-0">{{ gym.gym_name }}</v-card-title>
        <v-card-subtitle @click="openGymDetails(gym)">{{ gym.gym_address }}</v-card-subtitle>
        <v-card-actions>
          <v-row no-gutters>
            <v-btn @click="showDeleteConfirmation(gym.user_id, statusChange)" color="red" text>Delete Gym</v-btn>
            <v-btn @click="openSingleGymSummary(gym.user_id)" color="success" text>Gym Summery</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-bottom-sheet scrollable persistent inset v-model="addNew">
      <AddNewGym @close="addNew = false; getGyms();" type="COM"></AddNewGym>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import AddNewGym from "@/components/AddNewGym";

export default {
  name: "CommercialGyms",
  components: {AddNewGym},
  data: () => ({
    search: '',
    loading: true,
    gyms:[],
    addNew: false
  }),
  methods:{
    openSingleGymSummary(gym_id){
      this.$router.push({name: 'Single Commercial Gym Subscription Summery', params: {gym_id: gym_id}})
    },
    openGymDetails(gym){
      this.$router.push({name: 'Gym Overview', params: {id: gym.user_id.toString()}})
    },
    openGymSummary(){
      this.$router.push({name: 'Commercial Gym Subscription Summery', params: {gyms: this.gyms}})
    },
    getGyms(){
      this.gyms = [];
      this.loading = true;
      let SK = 'ALL'
      if(this.search !== ''){
        SK = this.search;
      }
      authClient.post('gyms/commercial/get',{
        search_key: SK
      }).then((response) => {
        console.log(response.data);
        this.gyms = response.data;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    statusChange(id) {
        authClient.post('/status/change',{
            status: '403',
            tableId: 1,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getGyms()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
    },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
  },
  mounted() {
    this.getGyms();
  }
}
</script>

<style scoped>

</style>
