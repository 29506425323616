<template>
    <v-container>
      <v-row no-gutters>
        <v-text-field v-model="search" outlined rounded label="Search Service..."></v-text-field>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="siteAds">
          <template v-slot:item.desc="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.desc">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.image_path="{ item }">
            <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.image_path">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.ad_url="{ item }">
            <v-btn outlined rounded target="_blank" :href="item.ad_url">
              Visit
            </v-btn>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip
              class="ma-2"
              :color=" item.status === '1' ? 'success': 'warning'"
            >
            {{ item.status === "1" ? 'Approved' : item.status === "2" ? 'Not Approved' : '' }}
            </v-chip>
          </template>
  
          <template v-slot:item.description="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-btn @click="statusChange(item.id, item.status === '1'? '2' : '1')" icon>
              <v-icon :color=" item.status === '1' ? 'warning' : 'green'"> {{ item.status === '1'? 'mdi-pause' : 'mdi-check'}}</v-icon>
            </v-btn>
            <v-btn @click="statusChange(item.id, 3)" icon>
              <v-icon color="red"> mdi-cancel</v-icon>
            </v-btn>
          </template>
          

        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
        <AddSiteAd :site_ads='site_ads' :domain="domain" :orderList="orderList" :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import AddSiteAd from "@/components/AddSiteAd.vue";
  
  export default {
    name: "ApproveService",
    components: {AddSiteAd},
    data: () => ({
      loading: true,
      domain:'',
      orderList:[],
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      site_ads: null,
      siteAds: [],
      headers: [
        {text: "ID", value: "id"},
        {text: "Service Name", value: "name"},
        {text: "Gym Name", value: "gym_name"},
        {text: "price", value: "price"},
        {text: "Image", value: "image_path"},
        {text: "Description", value: "description"},
        {text: "Status", value: "status"},
        {text: "Actions", value: "actions"},
      ],
      search: '',
    }),
    methods: {
      statusChange(id, status) {
        authClient.post('/status/change',{
            status: status,
            tableId: 6,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getSiteAds()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
      },
      getSiteAds() {
        authClient.get('/gym-services/pending/approve-list').then((response) => {
          let data = response.data[0]
          if(data.status){
            this.siteAds = data.data.result;
            this.domain = data.data.domain;
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getSiteAds();
      }
    },
    mounted() {
      this.getSiteAds();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  