<template>
    <v-container>
      <v-row no-gutters>
        <v-text-field v-model="search" outlined rounded label="Search Story..."></v-text-field>
        <v-btn :disabled="loading" @click="addStoryDialog = true;editExercise = null; isEdit = false" class="ml-2" rounded height="56" elevation="0" dark>
          <v-icon>mdi-plus</v-icon>
          Add Plan
        </v-btn>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="siteAds">
  
          <template v-slot:item.discounted_percentage="{ item }">
            {{item.discounted_percentage}}%
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-btn @click="isEdit = true; Gym_plans = item; addStoryDialog = true" icon >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="showDeleteConfirmation(item, deleteItem)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
        <AddComGymPlan :plan='Gym_plans' :gym_id="$route.query.gym_id" :domain="domain" :orderList="orderList" :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import AddComGymPlan from "@/components/AddComGymPlan.vue";
  
  export default {
    name: "ComGymPlans",
    components: {AddComGymPlan},
    data: () => ({
      loading: true,
      domain:'',
      orderList:[],
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      Gym_plans: null,
      siteAds: [],
      headers: [
      {text: "ID", value: "id"},
        {text: "Name", value: "name"},
        {text: "Duration(days)", value: "duration_amount"},
        {text: "Price (MVR)", value: "price"},
        {text: "Discount", value: "discounted_percentage"},
        {text: "Final Price (MVR)", value: "real_price"},
        {text: "Actions", value: "actions"},
      ],
      search: '',
    }),
    methods: {
      deleteItem(item) {
          authClient.post('status/change',{
            tableId: 9,
            resultId: item.id,
            status : '403'
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getPlans()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.ad_img
            }).then((response) => {
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      getPlans() {
        authClient.post('/commercial-gym/plan/list',{"gym_id":this.$route.query.gym_id}).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.siteAds = data.data.result;
            this.domain = data.data.domain;
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getPlans();
      }
    },
    mounted() {
      this.getPlans();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  