<template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <span class="headline">{{ edit ? 'Edit':'Add' }} Expence</span>
          <v-spacer/>
          <v-btn @click="$emit('close')" icon color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider class="mb-4"/>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="290px"
              >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  :rules="[rules.required]"
                      outlined
                      v-model="date"
                      label="Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                  </template>
                  <v-date-picker
                  v-model="date"
                  scrollable
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                  >
                      Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                  >
                      OK
                  </v-btn>
                  </v-date-picker>
          </v-dialog>
          <v-select @change="getMembers()" :rules="[rules.required]" :items="[...categories]" v-model="category_id" class="rounded-lg" item-text="name" item-value="id"  outlined label="Category"/>
          <v-row no-gutters>
            <v-col class="mr-1">
              <v-text-field outlined  :rules="[rules.required]" type="number" min="1" label="Sub Total" v-model="sub_total"></v-text-field>
            </v-col>
            <v-col class="mr-1">
              <v-text-field outlined :rules="[rules.required]"  type="number" min="1" label="Tax" v-model="tax"></v-text-field>
            </v-col>
            <v-col class="mr-1">
              <v-text-field outlined  :rules="[rules.required]" type="number" min="1" label="Total" v-model="total"></v-text-field>
            </v-col>
          </v-row>
          <v-textarea
          :rules="[rules.required]"
            name="input-1"
            rows="3"
            v-model="description"
            outlined
            label="Description"
          ></v-textarea>
          <v-row justify="end" no-gutters>
            <v-btn :loading="loading" @click="saveOrUpdate" width="128" elevation="0"  height="56" color="primary">Save</v-btn>
          </v-row>
      </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "AddFoodDialog",
    props: {
      edit: Boolean,
      categories: Array,
      food: Object
    },
    
    data: () => ({
      rules: {
      required: value => !!value || 'Required.',
    },
      loading: false,
      date: '',
      description: '',
      category_id: '',
      sub_total: '',
      tax: '',
      total: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,

    }),
    methods:{
      updateFood(){
        authClient.post('/expense/update', {
          "expenseId": this.food.id,
          "description" : this.description,
          "categoryId" : this.category_id,
          "sub_total" : this.sub_total,
          "tax" : this.tax,
          "total" : this.total,
          "date" : this.date,
        }).then((response) => {
              this.$emit('close');
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      },
      submitForm(){
        
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;

          authClient.post('/expense/add', {
            "description" : this.description,
            "categoryId" : this.category_id,
            "sub_total" : this.sub_total,
            "tax" : this.tax,
            "total" : this.total,
            "date" : this.date,
          })
          .then((response) => {
              this.$emit('close');
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      
          }else{
            this.loading = false
          }
      },
      saveOrUpdate(){
        this.loading = true;
        if(this.edit) {
          this.updateFood();
        } else {
          this.submitForm();
        }
      }
    },
    mounted() {
      if(this.edit){
        this.category_id = this.food.category_id;
        this.sub_total = this.food.sub_total;
        this.total = this.food.total;
        this.date = this.food.date;
        this.tax = this.food.tax;
        this.description = this.food.description;
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  