import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"outlined":"","rounded":"","label":"Search Food..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VBtn,{staticClass:"ml-2",attrs:{"rounded":"","height":"56","elevation":"0","dark":""},on:{"click":function($event){_vm.addFoodDialog = true;_vm.editFood = null; _vm.isEdit = false}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Add Food ")],1)],1),_c(VCard,{attrs:{"outlined":""}},[_c(VDataTable,{attrs:{"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.foods},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.isEdit = true; _vm.editFood = item; _vm.addFoodDialog = true}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFood(item.id)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}])})],1),(_vm.addFoodDialog)?_c(VDialog,{attrs:{"max-width":"768","persistent":""},model:{value:(_vm.addFoodDialog),callback:function ($$v) {_vm.addFoodDialog=$$v},expression:"addFoodDialog"}},[_c('AddFoodDialog',{attrs:{"food":_vm.editFood,"edit":_vm.isEdit},on:{"close":_vm.closeDialogs}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }