import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.time,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Picker in dialog","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c(VTimePicker,{attrs:{"full-width":""},on:{"input":_vm.emitValue},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.time)}}},[_vm._v(" OK ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }