<template>
    <v-container>
      <v-card outlined elevation="0">
        <v-card-title class="d-flex justify-space-between">
          <template>
          <span class="ml-2"><v-icon>mdi-wallet</v-icon>Gym Subscriptions</span>
          </template>
          <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="6"
      >
      <v-text-field v-model="search" outlined class="rounded-lg" label="Search Clients..." append-icon="mdi-magnify" ></v-text-field>
        </v-col>
        </v-card-title>
        <v-data-table
            :search="search"
            :headers="headers"
            :items="transactions"
            :loading="loading"
            :items-per-page="10"
            class="elevation-1">
          <template v-slot:item.created_at="{ item }">
            {{ item.created_at.split('T')[0] }}
          </template>
          <template v-slot:item.end_date="{ item }">
             {{  ValidDate(item)  }}
        </template>
          <template v-slot:item.type="{ item }">
            <v-chip dark :color="item.type === 'Credit' ? 'green' : 'red'">{{ item.type }}</v-chip>
          </template>
          <template v-slot:item.clients="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.clients">
              View
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Member Info</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
            <GymSubscribedClients :members="selectedDescription"/>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import TopupDialog from "@/components/TopupDialog";
  import GymSubscribedClients from "@/components/GymSubscribedClients";
  
  
  export default {
    components: {TopupDialog,GymSubscribedClients},
    name: "ComGymSubscriptions",
    props:{
      user_id:{
        required: true
      },
      isNotTopup:{
        required: false
      }
    },
    data: () => ({
      date:null,
      search:null,
      selectedDescription:[],
      descriptionViewDialog:false,
      dates: [],
      menu: false,
      modal: false,
      menu2: false,
      isShowTopup: true,
      topupDialog: false,
      transactions: [],
      loading: true,
      headers: [
        { text: 'Client Id', value: 'user.id' },
        { text: 'Client', value: 'user.email' },
        { text: 'Start Date', value: 'start_date' },
        { text: 'Valid till', value: 'end_date' }
      ],
    }),
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods:{
      getTransactions(){
        this.loading = true;
        authClient.post('/fitness/commercial-gyms/subscriptions',{
          gymId:this.user_id,
          'dateRange':this.dates
        }).then(value => {
          this.transactions = value.data;
          console.log(value.data);
          this.loading = false;
        });
      },ValidDate(item) {

        var options = { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric' 
        };

          const currentDate = new Date();
          const newDate = new Date(item.end_date);
          const differenceMs = newDate - currentDate;
          const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
          return `${newDate.toLocaleDateString('en-GB', options)} (${daysDifference} days left)`;
      
    },
      close(){
        this.getTransactions()
        this.topupDialog = false;
        this.$emit('close');
      }
    },
    mounted() {
      this.dates = [new Date().toISOString().split('T')[0]]
      this.getTransactions();
      
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  