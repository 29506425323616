<template>
    <v-card class="rounded-t-lg rounded-b-0">
      <v-card-title>
        <v-row no-gutters>
             Update Blood Sugar
          <v-spacer/>
          <v-btn color="red" icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="fastingSugar" type="number" placeholder="Fasting Blood Sugar (mg/dl)" :rules="[rules.required]" outlined label="Fasting Blood Sugar (mg/dl)"/>
          <v-text-field v-model="postSugar" type="number" placeholder="Post Blood Sugar (mg/dl)" :rules="[rules.required]" outlined label="Post Blood Sugar (mg/dl)"/>
        </v-form>
      </v-card-text>
  
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="upsertPlan" dark large color="primary">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "UpdateBMIData",
    data: () => ({
      rules: {
      required: value => !!value || 'Required.',
    },
    topupType: [
      {text: 'credit', value: '1'},
      {text: 'debit', value: '2'},
    ],
    valid:false,
      editSheet: false,
      fastingSugar:"",
      postSugar:"",
    }),
    methods:{
      close(){
        this.$emit('close')
      },
      upsertPlan(){
      this.$refs.form.validate();
      if(this.valid){
        this.loading = true;
        authClient.post(`/fitness/gym/${this.data}/blood-sugar`, {"fbs": this.fastingSugar, "rbs":this.postSugar}).then(res=>{
          let response_data = res.data
              if(response_data.success){
                this.close();
                this.$toast.success({
                  title: 'Ok',
                  message: 'success',
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.error,
              });
              }
        }).catch(err=>{
          console.log(err);
        }).finally(()=>{
          this.loading = false;
        })
      }
    },
    },
    mounted() {
      this.fastingSugar = this.BloodSugar_Data.fasting_blood_sugar;
      this.postSugar = this.BloodSugar_Data.random_blood_sugar;
    },
    props: {
      data: {
        required: true
      },
      BloodSugar_Data: {
        required: true
      },
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  