<template>
    <!-- <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :return-value.sync="time"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      max-width="290px"
      min-width="290px"
    >
        <template v-slot:activator="{ on }">
          
          <v-text-field
            v-model="time"
            :label="title"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time"
          full-width
          @click:minute="$refs.menu.save(time)"
          @input="emitValue"
        ></v-time-picker>
    </v-menu> -->
    <v-dialog
        ref="dialog"
        v-model="modal2"
        :return-value.sync="time"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            label="Picker in dialog"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
        @input="emitValue"
          v-if="modal2"
          v-model="time"
          full-width
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(time)"
          >
            OK
          </v-btn>
        </v-time-picker>
      </v-dialog>
</template>

<script>
import {authClient} from "@/plugins/http"
import { reactive, computed } from "vue";


export default {

  name: "TimePicker",
  props: {
    edit: Boolean,
    time_data: String,
    title:String
  },
  data: () => ({
    UserTypes: [
    { text: 'Full Time', value: '0'},
    { text: 'Specific Time', value: '1'}
  ],
    time: null,
    menu2: false,
    modal2: false,
    loading: false,
  }),
  methods:{
    emitValue() {
      const [hours, minutes] = this.time.split(':');
      this.$emit('value-emitted', `${hours}:${minutes}:00`);
    }
  },
  mounted() {
    if(this.edit){
      this.time = this.time_data
    }
  }
}
</script>
