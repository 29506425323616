<template>
  <v-container>
    <v-progress-linear class="mb-4" v-if="loading"></v-progress-linear>
    <v-row no-gutters class="mb-4">
      <span class="text-h5 black--text font-weight-bold">Add/Edit a Newsletter</span>
      <v-spacer/>
      <v-btn @click="UpdateOrAddNewsletter" elevation="0" color="primary" >
        <v-icon class="mr-2">mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-row>
    <v-divider class="mb-4"/>
    <v-text-field v-model="title" outlined  label="Title"></v-text-field>
    <v-file-input v-model="image"  prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Image" class="mr-1" outlined accept="image/*"></v-file-input>
    <v-file-input v-model="pdf"  prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Pdf" class="mr-1" outlined accept="application/pdf"></v-file-input>
    <div style="height: 100%">
      <vue-editor style="height: 800px" v-model="article"/>
    </div>
    <div style="height: 64px;"/>
    <v-file-input v-model="thumbnail"  prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Thumbnail" accept="image/*" class="mr-1" outlined></v-file-input>
    <v-row class="mt-2" no-gutters>
      <v-col
      class="ml-n4"
        cols="12"
        sm="6"
              >
      <template >
        <div class="mr-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Shedule date"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :active-picker.sync="activePicker"
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </div>
      </template>
      </v-col>
      
      <v-col
        class="ml-4"
        cols="12"
        sm="6"
              >
              <template>
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="time"
                  persistent
                  width="290px"
                  class="mr-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="time"
                      label="Shedule time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    @input="emitValue"
                      v-if="modal2"
                      v-model="time"
                      full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal2 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(time)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "AddNewsletterView",
  props: {
    resource: Object
  },
  data: () => ({
    time: null,
    menu2: false,
    modal2: false,
    edit:false,
    activePicker:null,
    date: null,
    menu: false,
    loading: false,
    title: "",
    article: '',
    image: null,
    pdf: null,
    thumbnail: null,
    imageLink: null,
  }),
  methods: {

    updateFile(file_name, file, unique_name) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('path', 'resources/');
        formData.append('unique_name', unique_name);
        formData.append('file_name', file_name);

        authClient.post('/users/file-uploads/file-update', formData)
          .then(response => {
            resolve({
              file_path: response.data.file_path,
              status: true
            });
          })
          .catch(error => {
            resolve({
              status: false
            });
          });
      });
    },

    async UpdateNewsletter() {
      if (this.title !== '' && this.description !== '') {
        this.loading = true;

          let pdf_link = this.resource.pdf;
          let thumbnail_link = this.resource.thumbnail;
          let image_link = this.resource.image;

          if( this.image !== null ){
            let result = await this.updateFile(this.resource.image, this.image, 'animation_');
            if( !result.status){
              this.$toast.error({
                  title: 'error',
                  message: "Faild to upload image",
              });
              return;
            }

            image_link = result.file_path
          }

          if( this.pdf !== null ){
            let result = await this.updateFile(this.resource.pdf, this.pdf, 'newsletterPdf_');
            if( !result.status){
              this.$toast.error({
                  title: 'error',
                  message: "Faild to upload pdf",
              });
              return;
            }
            pdf_link = result.file_path
          }

          if( this.thumbnail !== null ){
            let result = await this.updateFile(this.resource.thumbnail, this.thumbnail, 'newsThumbnail_');
            if( !result.status){
              this.$toast.error({
                  title: 'error',
                  message: "Faild to upload thumbnail",
              });
              return;
            }
            thumbnail_link = result.file_path
          }
        
          authClient.post('/fitness/newsletters/actions/add-or-update',{
            id: this.resource.id,
            title: this.title,
            image: image_link,
            thumbnail: thumbnail_link,
            pdf:pdf_link,
            article: this.article,
            shedule_date: this.date,
            shedule_time:this.time
          }).then((res) => {
            console.log(res);
            this.loading = false;
            this.$router.push('/newsletters');
          }).catch(() => {
            this.loading = false;
          });
        }
      this.loading = false;
    },
    AddNewsletter () {
      if(this.title !== '' && this.date !== null  && this.time !== null && this.description !== '' && this.image !== null && this.thumbnail !== null){
        let formData = new FormData();
        formData.append('image', this.image);
        formData.append('thumbnail', this.thumbnail);
        if(this.pdf !== null){
          formData.append('pdf', this.pdf);
        }

        authClient.post('users/file-uploads/newsletter-files',formData).then( async (response) => {
         
          authClient.post('/fitness/newsletters/actions/add-or-update', {
            pdf: this.pdf == null? '' : response.data.pdf,
            title: this.title,
            image: response.data.image,
            thumbnail: response.data.thumbnail,
            article: this.article,
            shedule_date: this.date,
            shedule_time:this.time
          }).then((res) => {
            this.loading = false;
            this.$router.push('/newsletters');
          }).catch(() => {
            this.loading = false;
          });
        }).catch((error) => {
          console.log(error);
        });
      } else {
        window.alert('Please fill required the fields');
        this.loading = false;
      }
    },
    UpdateOrAddNewsletter() {
    
      if (this.resource != null) {
        this.UpdateNewsletter();
      } else {
        this.AddNewsletter();
      }
    },
    save (date) {
        this.$refs.menu.save(date)
    },
    emitValue() {
      const [hours, minutes] = this.time.split(':');
      console.log(`${hours}:${minutes}:00`)
      this.$emit('value-emitted', `${hours}:${minutes}:00`);
    }
  },
  mounted() {
    if (this.resource != null) {
      this.edit = true;
      this.title = this.resource.title;
      this.article = this.resource.article;
      this.date = this.resource.shedule_date,
      this.time = this.resource.shedule_time
    }
  }
}
</script>

<style scoped>

</style>
