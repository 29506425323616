<template>
     <v-container fluid>
      <v-row>
        <v-col cols="12" md="6" lg="3">
          <router-link :to="'/members'" class="router--">
            <CountView :count="counts.member_count" :title="'All Members'" :color_type="1" :icon_img="'mdi-account-group'"/>
          </router-link>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <router-link :to="'/members'" class="router--">
            <CountView :count="counts.pro_member_count" :title="'Pro Members'" :color_type="2" :icon_img="'mdi-professional-hexagon'"/>
          </router-link>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <router-link :to="'/trainers'" class="router--">
            <CountView :count="counts.trainer_count" :title="'All Coaches'" :color_type="1" :icon_img="'mdi-account-star-outline'"/>
          </router-link>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <router-link :to="'/trainers'" class="router--">
            <CountView :count="counts.pro_trainer_count" :title="'Pro Coaches'" :color_type="2" :icon_img="'mdi-professional-hexagon'"/>
          </router-link>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <router-link :to="'/doctors'" class="router--">
            <CountView :count="counts.doctors_count" :title="'Doctor Counts'" :color_type="1" :icon_img="'mdi-doctor'"/>
          </router-link>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <router-link :to="'/therapy-manage'" class="router--">
            <CountView :count="counts.therapy_count" :title="'Therapy Counts'"  :color_type="1" :icon_img="'mdi-account-eye'"/>
          </router-link>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import CountView from "@/components/CountView";
import {authClient} from "@/plugins/http";
export default {
  name: 'Home',
  components:{CountView},
  data() {
    return {
      counts: {
        users: 100,
        orders: 50,
        products: 75,
        revenue: 50000,
      },
    };
  },
  methods: {
    getCounts() {
      this.loading = true;
      authClient.get('/dashboard/counts').then(response => {
        this.counts = response.data;
        this.loading = false;
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getCounts()
  }
}
</script>

<style scoped>
/* Custom styles for the Dashboard component */
.light-blue-background {
  background-color: #f0ebf4; /* Light blue */
}

.dark-gray--text {
  color: #191970; /* Dark gray */
}

.v-card {
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.v-icon {
  margin-bottom: 1rem;
}

.router--{
  text-decoration: none;
}
.v-card-title {
  font-family: 'Quicksand', sans-serif;
}

.v-card-subtitle {
  color: #c0c0c0; /* Dark gray */
}
</style>
