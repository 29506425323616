import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCol,[_c('UserDetailsHeading',{staticClass:"mb-4",attrs:{"data":_vm.member,"subscription":_vm.member.subscriptions}}),_c(VDivider,{staticClass:"mb-4"}),_c(VRow,{staticClass:"mt-8",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-2",attrs:{"cols":"4"}},[_c('UserBaseDetails',{attrs:{"details":_vm.getDetails()}}),_c(VCard,{staticClass:"mt-4",attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Clients")]),_c(VCardText,_vm._l((_vm.clients),function(client,i){return _c(VCard,{key:i,staticClass:"mb-2 pl-0 ml-0",attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"pb-0"},[_c(VImg,{staticClass:"rounded-circle",attrs:{"max-height":"64","contain":"","max-width":"64","src":_vm.$store.state.s3BaseUrl + client.user.avatar_url,"alt":"avatar"}})],1),_c(VCardTitle,{staticClass:"pt-2"},[_vm._v(" "+_vm._s(client.user.name)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(client.user.email)+" ")])],1)}),1)],1)],1),_c(VSpacer),_c(VCol,{attrs:{"cols":"8"}},[_c('UserEWalletTransactions',{attrs:{"user_id":_vm.member.id}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }