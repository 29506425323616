<template>
    <v-container>

      <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-btn to="/holiday/manage" class="ml-2 px-10" rounded height="56" elevation="0" dark>
        Holiday Manage
      </v-btn>
    </v-row>
    
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-col
            bottom
            class="pb-0"
            cols="12"
            md="3"
            lg="3"
            >
            <v-select bottom dense @change="handleCountryChange" :items="country_list" v-model="selected_country" class="rounded-lg"   outlined label="Country"/>
            </v-col>
            <v-col
            bottom
            class="pb-0"
            cols="12"
            md="3"
            lg="3"
            >
            <v-select bottom dense @change="getHolidays" :items="country_holidays" v-model="selected_holiday" class="rounded-lg"   outlined label="Holiday Type"/>
            </v-col>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.details"></span>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    </v-container>
  </template>
  
  <script>
  import UserDetailsHeading from "@/components/UserDetailsHeading";
  import {authClient} from "@/plugins/http";
  export default {
    name: "CalanderView",
    components: {UserDetailsHeading},
    data: () => ({
      loading: true,
      image:null,
      holidays:[],
      country_list:[],
      country_holidays:[],
      imageUpload:false,
      holiday_object:{},
      imageUploadLoading:false,
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      selected_country:"",
      selected_holiday:"",
      weekday: [0, 1, 2, 3, 4, 5],
      focus: '',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      gym: {},
    }),
    methods:{
      getUserData(){
        return {
          id: this.gym.user.id,
          name: this.gym.gym_name,
          email: this.gym.user.email,
          phone: this.gym.user.phone,
          address: this.gym.address,
          avatar_url: this.gym.user.avatar_url,
          role: this.gym.user.role,
        }
      },
      getGymFromServer(){
        this.loading = true;
        authClient.get('/gyms/actions/'+this.$route.params.id+'/get').then(res=>{
          this.gym = res.data;
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
          console.log(err);
        });
      },
      getHolidayCounties(){
        this.loading = true;
        authClient.get('/holiday/country-list').then(res=>{
          let data = res.data[0].data.result;
          this.holiday_object = data;
          let countryKeys = Object.keys(data);
          this.country_list = countryKeys;
          console.log(countryKeys)

          this.loading = false;
        }).catch(err=>{
          this.loading = false;
          console.log(err);
        });
      },
      handleCountryChange(){

        if(this.selected_country != ""){

          this.country_holidays = this.holiday_object[this.selected_country].map(item=>{
            return item.name;
          })

        }

      },
      deleteGalleryItem(item) {
          this.$toast.question({
            timeout: 10000, // The time in milliseconds the toast will be displayed
            close: false, // Whether to show the close button
            overlay: true, // Whether to display an overlay behind the toast
            toastOnce: true, // Whether to show the toast only once
            id: 'deleteToast',
            zindex: 999,
            title: 'Confirmation',
            message: 'Are you sure you want to delete?',
            position: 'center',
            buttons: [
              ['<button><b>YES</b></button>', (instance, toast)=> {
                authClient.post('/users/file-uploads/delete-file', {
                  filePath: item.image_path
                }).then(rs => {
                  console.log(rs);
                  authClient.post('/admin/gyms/delete-gallery-item',{
                    id: item.id
                  }).then(rs => {
                    console.log(rs);
                    this.getGymFromServer();
                  })
                });
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
              }, true],
              ['<button>NO</button>', function (instance, toast) {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
              }],
            ]
          });
        },
      getHolidays() {
        authClient.post('/holiday/filter-list',{
          "country" : this.selected_country,
          "holiday" : this.selected_holiday
        }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.holidays = data.data.result;
            this.getEvents()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      getEventColor (event) {
        return event.color
      },
      getEvents () {
        let events = []
        
        for (let i = 0; i < this.holidays.length; i++) {
            
            let holiday_obj = this.holidays[i];
            this.focus = holiday_obj.holiday_dates[0].date;
            if(holiday_obj.date_type == "2" ){

                events.push({
                    name: holiday_obj.name,
                    start: holiday_obj.holiday_dates[0].date,
                    end:   holiday_obj.holiday_dates[ holiday_obj.holiday_dates.length -1 ].date,
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: false,
                })

            }else{
                let color = this.colors[this.rnd(0, this.colors.length - 1)];
                holiday_obj.holiday_dates.map((item)=>{
                    events.push({
                        name: holiday_obj.name,
                        start: item.date,
                        end:   item.date,
                        color: color,
                        timed: false,
                    })
                })
            }

          
        }
  
        this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
    //   updateRange ({ start, end }) {
    //     const events = []
  
    //     const min = new Date(`${start.date}T00:00:00`)
    //     const max = new Date(`${end.date}T23:59:59`)
    //     const days = (max.getTime() - min.getTime()) / 86400000
    //     const eventCount = this.rnd(days, days + 20)
  
    //     for (let i = 0; i < eventCount; i++) {
    //       const allDay = this.rnd(0, 3) === 0
    //       const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //       const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //       console.log(first);
    //       const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //       const second = new Date(first.getTime() + secondTimestamp)
  
    //       events.push({
    //         name: "Sdsdsdd",
    //         start: first,
    //         end: second,
    //         color: this.colors[this.rnd(0, this.colors.length - 1)],
    //         timed: !allDay,
    //       })
    //     }
  
    //     this.events = events
    //   },
    },
    mounted() {
      this.getHolidayCounties()
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  