<template>
  <v-container>
    <v-row no-gutters>
      <v-btn
        @click="BackToUser()"
        class="mr-2"
        outlined
        large
        fab
      >
      <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-text-field v-model="search" outlined rounded label="Search User..."></v-text-field>
      <v-btn :disabled="loading" @click="addStoryDialog = true;editExercise = null; isEdit = false" class="ml-2" rounded height="56" elevation="0" dark>
        <v-icon>mdi-plus</v-icon>
        Add F&Q
      </v-btn>
    </v-row>
    <v-card outlined>
      <v-data-table
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="courtesyNotes">
        <template v-slot:item.description="{ item }">
          <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
            View
          </v-btn>
        </template>

        <template v-slot:item.image="{ item }">
          <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.image">
            View
          </v-btn>
        </template>

        <template v-slot:item.ad_url="{ item }">
          <v-btn outlined rounded target="_blank" :href="item.ad_url">
            Visit
          </v-btn>
        </template>


        <template v-slot:item.actions="{ item }">
          <v-btn @click="isEdit = true; courtesy_Notes = item; addStoryDialog = true" icon >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="showDeleteConfirmation(item, deleteItem)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
      <AddFaqDialog :mainfaq_id="faq_id" :notes_obj='courtesy_Notes' :edit="isEdit" @close="closeDialogs"/>
    </v-dialog>

    <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <span class="headline">Description</span>
            <v-spacer/>
            <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider class="mb-4"/>
        <v-card-text>
          {{ selectedDescription }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

import AddFaqDialog from "@/components/AddFaqDialog.vue";

export default {
  name: "FaqSubCategory",
  components: {AddFaqDialog},
  data: () => ({
    loading: true,
    domain:'',
    orderList:[],
    addStoryDialog: false,
    descriptionViewDialog:false,
    optionalViewDialog:false,
    selectedOptional: null,
    selectedDescription: null,
    isEdit: false,
    courtesy_Notes: null,
    courtesyNotes: [],
    headers: [
      {text: "ID", value: "id"},
      {text: "Title", value: "title"},
      {text: "Description", value: "description"},
      {text: "Actions", value: "actions"},
    ],
    search: '',
  }),
  props:{
    faq_id: {
      type: Number,
      required: true
    }
},
  methods: {
    BackToUser(){
      this.$router.push({name: 'F&Q Main Category'});
    },
    deleteItem(item) {
        authClient.post('delete',{
          tableId: 13,
          resultId: item.id
        }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
            });
            this.getNotes()

          authClient.post('/users/file-uploads/delete-file',{
          filePath: item.ad_img
          }).then((response) => {
          }).catch((error) => {
            console.log(error);
          });
          
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
        }
          
        }).catch((error) => {
          console.log(error);
        });
    },
    showDeleteConfirmation(item, callback) {
      this.$toast.question({
        timeout: 10000, // The time in milliseconds the toast will be displayed
        close: false, // Whether to show the close button
        overlay: true, // Whether to display an overlay behind the toast
        toastOnce: true, // Whether to show the toast only once
        id: 'deleteToast',
        zindex: 999,
        title: 'Confirmation',
        message: 'Are you sure you want to delete?',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', function (instance, toast) {
            callback(item)
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          }, true],
          ['<button>NO</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          }],
        ]
      });
    },
    getNotes() {
      authClient.get(`/faq/sub/list/${this.faq_id}`).then((response) => {
        let data = response.data
          this.courtesyNotes = data;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
      this.loading = false;
    },
    closeDialogs() {
      this.addStoryDialog = false;
      this.getNotes();
    }
  },
  mounted() {
    this.getNotes();
  }
}
</script>

<style scoped>

</style>
