<template>
    <v-container>
        <template class="pt-5">
            <v-row justify="center" class="pt-15">
                <v-expansion-panels popout v-model="panel" multiple>
                    <v-expansion-panel active
                    >
                        <v-expansion-panel-header>No Subscription</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col sm="6" v-for="(item, index) in selectedWidget">
                              <v-checkbox
                                :key="item.id"
                                v-model="item.selected"
                                :label="item.name"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-btn @click="upsertPlan('1')" large color="white">
                            <v-icon class="mr-2">mdi-check</v-icon>
                            Save</v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                    >
                        <v-expansion-panel-header>Free Trial</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col sm="6" v-for="(item, index) in selectedFreeWidget">
                              <v-checkbox
                                :key="item.id"
                                v-model="item.selected"
                                :label="item.name"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-btn @click="upsertPlan('2')" large color="white">
                            <v-icon class="mr-2">mdi-check</v-icon>
                            Save</v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                    >
                        <v-expansion-panel-header>Pro User</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col sm="6" v-for="(item, index) in selectedProWidget">
                              <v-checkbox
                                :key="item.id"
                                v-model="item.selected"
                                :label="item.name"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-btn @click="upsertPlan('3')" large color="white">
                            <v-icon class="mr-2">mdi-check</v-icon>
                            Save</v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
        </template>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "TrainerWidget",
    data: ()=>({
      panel: [],
      selectedWidget: [], 
      selectedFreeWidget: [], 
      selectedProWidget: [], 
      plans: [],
      selectedItems: [],
      loading: true,
      editing: null
    }),
    methods: {
      upsertPlan(type){

          let sentData = {}
          if(type == '1'){
            sentData = {
              "type":1,
              "packages": this.selectedWidget
            }
          }else if(type == '2'){
            sentData = {
              "type":2,
              "packages": this.selectedFreeWidget
            }
          }else{
            sentData = {
              "type":3,
              "packages": this.selectedProWidget
            }
          }
          authClient.post('/widget/trainer-update', sentData).then(res=>{
            let data = res.data[0]
            if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
            });
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch(err=>{
            console.log(err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      getPackages(){
        this.loading = true;
        authClient.get('/widget/trainer-list').then(res=>{
          this.selectedWidget = res.data.fresh
          this.selectedFreeWidget = res.data.free
          this.selectedProWidget = res.data.pro
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
      },
    },
    mounted() {
      this.getPackages()
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  