<template>
    <v-card>
      <v-card-title>
        {{ this.plan !== null ? 'Edit' : 'Add' }} a Coupon
        <v-spacer/>
        <v-btn color="red" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
  
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="data.name" :rules="[rules.required]" outlined label="Coupon Name" class="mt-3"/>
          <v-text-field v-model="data.code" :rules="[rules.required]" outlined label="Coupon Code"/>
          <v-text-field v-model="data.use_count" :rules="[rules.required]" outlined label="Max Use Count"/>
          <v-row no-gutters>
            <v-select :items="couponTypes" item-text="text" item-value="value" v-model="data.coupon_type" :rules="[rules.required]" outlined label="Select The Type"/>
          </v-row>
          <v-select v-if="data.coupon_type==3" :items="gymWiseData" item-text="gym_name" item-value="user_id" v-model="data.gym_id" :rules="[rules.required]" outlined label="Select the gym"/>
          <v-select v-if="data.coupon_type==3" :items="gymWiseData.map(item=>{ if(item.user_id== data.gym_id){ return item.gym_services }})[0]" item-text="name" item-value="id" v-model="data.service_id" :rules="[rules.required]" outlined label="Select Gym Service"/>
          
          <v-select v-if="data.coupon_type==1" :items="plans" item-text="name" item-value="id" v-model="data.subscription_id" :rules="[rules.required]" outlined label="Select Plan"/>
          <v-select v-if="data.coupon_type==2" :items="GymTypes" item-text="text" item-value="value" v-model="data.gym_type" :rules="[rules.required]" outlined label="Select Gym Type"/>

          <template v-if="data.coupon_type==2">
            <v-select v-if="data.gym_type==1" :items="gymWiseData" item-text="gym_name" item-value="user_id" v-model="data.gym_id" :rules="[rules.required]" outlined label="Select the gym"/>
            <v-select v-if="data.gym_type==2" :items="comGyms" item-text="gym_name" item-value="user_id" v-model="data.gym_id" :rules="[rules.required]" outlined label="Select the gym"/>
          </template>
          

          <template>
            <v-row no-gutters>
              <v-text-field  append-icon="mdi-percent" type="number" min="0" max="100" v-model="data.discount_percentage" :rules="[rules.required]" outlined label="Discounted Percentage" class="pl-2"/>
              <v-text-field  type="number" min="0" v-model="data.max_value" :rules="[rules.required]" outlined label="Max Saving" class="pl-2"/>
            </v-row>
          </template>

          <template >
            <v-row no-gutters>
              <div class="mr-0  col-sm-6">
                <v-menu
                  ref="menu_1"
                  v-model="menu_1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.valid_from"
                      label="Valid From"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.valid_from"
                    :active-picker.sync="activePicker"
                    min="1950-01-01"
                    @change="save_1"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mr-0  col-sm-6">
                <v-menu
                  ref="menu_2"
                  v-model="menu_2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="data.valid_till"
                      label="Valid Until"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.valid_till"
                    :active-picker.sync="activePicker"
                    min="1950-01-01"
                    @change="save_2"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-row>
          </template>
          
  
        </v-form>
      </v-card-text>
  
  
      <v-card-actions style="background: #1976d2">
        <v-spacer/>
        <v-btn @click="upsertPlan" large color="white">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "UpsertCoupon",
    data:()=>({
      gymWiseData:[],
      comGyms:[],
      plans:[],
      loading:false,
      rules: {
        required: value => !!value || 'Required.',
      },
      couponTypes: [
        {text: 'For Subscriptions', value: '1'},
        {text: 'Gym wise', value: '2'},
        {text: 'Gym Services', value: '3'},
      ],
      GymTypes: [
        {text: 'Exclusive Gyms', value: '1'},
        {text: 'Commercial Gym', value: '2'},
      ],
      menu_1: false,
      menu_2: false,
      activePicker:null,
      data: {
        id:null,
        valid_from:"",
        valid_till:"",
        name:"",
        description:"",
        use_count:0,
        code:0,
        coupon_type:"1",
        selected_gym_id:null,
        service_id:null,
        gym_type:null,
        gym_id:null,
        subscription_id:null,
        max_value:0,
        discount_percentage:0
      },
      valid: false,
    }),
    methods:{
      upsertPlan(){
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;
          let url = this.data.id == null ?  '/coupon/actions/add' : '/coupon/actions/edit'
          authClient.post(url, this.data).then(res=>{
            let response_data = res.data[0]
              if(response_data.status){
                this.$emit('close');
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            
          }).catch(err=>{
            console.log(err);
          }).finally(()=>{
            this.loading = false;
          })
        }
      },
      getServices(){
      this.loading = true;
      authClient.get('/gym-services/gym-wise-services').then(res=>{
        this.gymWiseData = res.data;
        console.log(this.plans);
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
      },
      getGyms(){
        this.loading = true;
        let SK = 'ALL'
        if(this.search !== ''){
          SK = this.search;
        }
        authClient.post('gyms/commercial/search',{
          search_key: SK
        }).then((response) => {
          console.log(response.data);
          this.comGyms = response.data;
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
      save_1 (date) {
        this.$refs.menu_1.save(date)
    },
    save_2 (date) {
        this.$refs.menu_2.save(date)
    },
      getPlans(){
        this.loading = true;
        authClient.get('/common/subscriptions/get-all').then(res=>{
          this.plans = res.data;
          console.log(this.plans);
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
    },
      close(){
        this.$emit('close');
      }
    },
    mounted() {
      this.getServices()
      this.getPlans()
      this.getGyms()
      if(this.plan !== null){
        this.data = this.plan;
      }
    },
    props:{
      plan:{
        type:Object,
        default:null
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  