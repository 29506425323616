import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"outlined":"","rounded":"","label":"Search New Medical Professionals..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCard,{attrs:{"outlined":"","loading":_vm.loading}},[_c(VDataTable,{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.doctors},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [_c(VChip,{attrs:{"dark":"","color":"red"}},[_vm._v("New")])]}},{key:"item.actions",fn:function({ item }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.toggleStatus(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.doctor.approved)?_c(VBtn,_vm._g(_vm._b({attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.toggleStatus(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-close-circle-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reject")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.viewDetails(item)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('span',[_vm._v("More Info")])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }