import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_vm._v(" Add an Ad "),_c(VSpacer),_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"outlined":"","label":"Ad Link to Open"},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),_c(VFileInput,{staticClass:"mr-1",attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-paperclip","label":"Image","outlined":""},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1)],1),_c(VCardActions,{staticStyle:{"background":"#1976d2"}},[_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.loading,"large":"","color":"white"},on:{"click":_vm.upsertAd}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }