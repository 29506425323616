<template>
    <v-container>
      <!-- <v-btn  icon @click="viewDetails()" color="black">
        <v-icon>mdi-arrow-left-circle</v-icon>
      </v-btn> -->
      <v-btn
           
          @click="viewDetails()"
          class="ma-2"
          outlined
          large
          fab
        >
        <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      <v-row no-gutters>
        <v-card class="ma-2 rounded-lg" width="40%" hover ripple v-for="(plan,i) in workout_plan" :key="i">
            <v-img
            :src=" $store.state.s3ResourcesBaseUrl + plan.animation_url"
           
            ></v-img>
            <v-card-body @click="edit(plan)">
          <v-card-title>
            {{ plan.title }}
            <v-spacer/>
          </v-card-title>
          <v-row class="d-flex justify-center ">
            <v-chip
              class="ma-2 rounded-lg text-center"
            >
              REPETITIONS : 
              {{ plan.repetitions }}
            </v-chip>

            <v-chip
              class="ma-2 rounded-lg"
            >
            Sets : 
              {{ plan.sets }}
            </v-chip>
            <v-chip
              class="ma-2 rounded-lg"
            >
             Weight: 
              {{ plan.weight }} Kg
            </v-chip>
          </v-row>
          <v-card-title>
            <span class="mr-2" >
              <!-- Max Saving{{ plan.max_value.toFixed(2) }} -->
            </span>
          </v-card-title>
          </v-card-body>
        </v-card>
      </v-row>
  
      <v-bottom-sheet scrollable inset persistent v-if="upsertASubscription" v-model="upsertASubscription">
        <UpsertCoupon :plan="editing" @close="close"/>
      </v-bottom-sheet>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import UpsertCoupon from "@/components/UpsertCoupon";
  
  export default {
    name: "ClientWorkouts",
    components: {UpsertCoupon},
    props:{
    workout_plan: {
      type: Array,
      required: true
    },
    member: {
        type: Object,
        required: true
      }
  },
    data: ()=>({
      search: '',
      plans: [],
      loading: true,
      upsertASubscription: false,
      editing: null
    }),
    methods: {
      viewDetails(){
        this.$router.push({
            name: 'Gym Member Workouts', // Use the route name
            params: { id: this.$store.state.user.id, member: this.member }, // Pass the 'id' parameter if needed
          });
          
      }
    },
    mounted() {
    //   this.getPlans();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  