
<template>
    <v-card>
      <v-card-title>
        {{ this.edit !== null ? 'Edit' : 'Add' }} a Notification
        <v-spacer/>
        <v-btn color="red" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
  
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row no-gutters>
              <v-col
                cols="12"
                sm="12"
              >
              <v-text-field  outlined  v-model="data.title" label="Notification Title" class="mr-1" :rules="[rules.required]" ></v-text-field>
            </v-col> 
          </v-row>
          <v-textarea :rules="[rules.required]" v-model="data.message" class="mx-2 mb-3" label="Message" rows="3" prepend-icon="mdi-comment"></v-textarea>
          <v-file-input v-model="current_file_img_1"  prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Image" class="mr-1" outlined></v-file-input>
          <!-- <v-row class="img-container mb-2" >
            <v-col  cols="12" class="img-container-1">
              <a @click="imgUpload()" href="javascript:void(0)">
                    <div class="main-img-container">
                      <div class="upload-lable">
                          <p tabindex="0" class="mt-4" role="button" data-bs-toggle="popover"
                              data-bs-trigger="focus" title=""
                              data-bs-content="Click below placeholder image (or current image) to upload/change your post image"
                              data-bs-original-title="How to Upload/Change Image">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                  viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                  class="feather feather-alert-circle mx-2">
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="12" y1="8" x2="12" y2="12"></line>
                                  <line x1="12" y1="16" x2="12.01" y2="16"></line>
                              </svg>
                          </p>
                      </div>
                      <v-img :src="image_src_1"
                          class="img-properties" alt=""></v-img>
                        <input  ref="imageUploadFeild_1" type="file"
                        v-on:change="viewImage( $event)" class="d-none">
                    </div>  
              </a>
            </v-col>
          </v-row> -->
          <v-row no-gutters>
            <v-select :items="notificationTypes" item-text="text" item-value="value" v-model="data.notification_type" :rules="[rules.required]" outlined label="Select The Type"/>
          </v-row>
          <v-text-field v-if="data.notification_type==2" v-model="data.email" :rules="[rules.required]"  outlined label="User email" type="email" class="mt-3"/>
          
          <v-select v-if="data.notification_type==5" :items="gymWiseData" item-text="gym_name" item-value="user_id" v-model="data.gym_id" :rules="[rules.required]" outlined label="Select the gym"/>
          <v-select v-if="data.notification_type==6" :items="comGyms" item-text="gym_name" item-value="user_id" v-model="data.gym_id" :rules="[rules.required]" outlined label="Select the gym"/>
          <v-select v-if="data.notification_type==7" :items="NsServices"  v-model="data.service_id" :rules="[rules.required]" outlined label="Select the service"/>

          <v-row class="mt-2" no-gutters>
            <v-col
            class="ml-n4"
              cols="12"
              sm="6"
                    >
            <template >
              <div class="mr-0">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Shedule date"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          :active-picker.sync="activePicker"
                          min="1950-01-01"
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </div>
            </template>
            </v-col>
            
            <v-col
              class="ml-4"
              cols="12"
              sm="6"
                    >
                    <template>
                      <v-dialog
                        ref="dialog"
                        v-model="modal2"
                        :return-value.sync="time"
                        persistent
                        width="290px"
                        class="mr-0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="time"
                            label="Shedule time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          @input="emitValue"
                            v-if="modal2"
                            v-model="time"
                            full-width
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal2 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save( `${time}:00`)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </template>
            </v-col>
          </v-row>
          
        </v-form>
      </v-card-text>
  
  
      <v-card-actions style="background: #1976d2">
        <v-spacer/>
        <v-btn :disabled="loading" @click="saveOrUpdate" large color="white">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "UpsertCoupon",
    data:()=>({
      gymWiseData:[],
      comGyms:[],
      plans:[],
      modal2:null,
      loading:false,
      rules: {
        required: value => !!value || 'Required.',
      },
      notificationTypes: [
        {text: 'For All Users', value: '1'},
        {text: 'For Sepcific User', value: '2'},
        {text: 'For Pro Members', value: '3'},
        {text: 'For Pro Coaches', value: '4'},
        {text: 'For Exclusive Gym Users', value: '5'},
        {text: 'For Commercial Gym Users', value: '6'},
        {text: 'For Services', value: '7'}
      ],
      NsServices: [
        {text: 'Physiotherapy', value: '1'}
      ],
      activePicker:null,
      date: null,
      menu: false,
      time:null,
      current_file_img_1:null,
      activePicker:null,
      image_src_1: "",
      data: {
        id:null, 
        message:"",
        title:"",
        email:"",
        service_id:"",
        gym_id:"",
        notification_type:null
      },
      valid: false,
    }),
    methods:{
      imgUpload() {
          this.$refs.imageUploadFeild_1.click();
      },
      viewImage(event) {
        if (event) {
            this.image_src_1 = URL.createObjectURL(event.target.files[0]);
            this.current_file_img_1 = event.target.files[0]
        }
        
      },
      submitForm(){
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;

          let formData = new FormData();
          
          if(this.current_file_img_1 != null) formData.append('image', this.current_file_img_1);
          formData.append('title', this.data.title);
          formData.append('message', this.data.message);
          formData.append('type', this.data.notification_type);
          formData.append('email', this.data.email);
          formData.append('gym_id', this.data.gym_id);
          formData.append('service_id', this.data.service_id);
          formData.append('shedule_date', this.date);
          formData.append('shedule_time', this.time);

          authClient.post('/notification/add',formData)
          .then((response) => {
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$emit('close');
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      
          }else{
            this.loading = false
          }
      },updateForm(){
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;

          let formData = new FormData();
          
          if(this.current_file_img_1 != null) formData.append('image', this.current_file_img_1);
          formData.append('notyId', this.data.id);
          formData.append('title', this.data.title);
          formData.append('message', this.data.message);
          formData.append('type', this.data.notification_type);
          formData.append('email', this.data.email);
          formData.append('gym_id', this.data.gym_id);
          formData.append('service_id', this.data.service_id);
          formData.append('shedule_date', this.date);
          formData.append('shedule_time', this.time);

          authClient.post('/notification/update',formData)
          .then((response) => {
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$emit('close');
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      
          }else{
            this.loading = false
          }
      },
      saveOrUpdate(){
      this.loading = true;
      if(this.edit) {
        this.updateForm();
      } else {
        this.submitForm();
      }
    },
      getServices(){
      this.loading = true;
      authClient.get('/gym-services/gym-wise-services').then(res=>{
        this.gymWiseData = res.data;
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
      },
      getGyms(){
        this.loading = true;
        let SK = 'ALL'
        if(this.search !== ''){
          SK = this.search;
        }
        authClient.post('gyms/commercial/search',{
          search_key: SK
        }).then((response) => {
          console.log(response.data);
          this.comGyms = response.data;
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    save (date) {
        this.$refs.menu.save(date)
    },
    emitValue() {
      const [hours, minutes] = this.time.split(':');
      this.$emit('value-emitted', `${hours}:${minutes}:00`);
    },
      getPlans(){
        this.loading = true;
        authClient.get('/common/subscriptions/get-all').then(res=>{
          this.plans = res.data;
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
    },
      close(){
        this.$emit('close');
      }
    },
    mounted() {
      this.getServices()
      this.getPlans()
      this.getGyms()
      if(this.edit){
        this.data.id = this.story.id, 
        this.data.message=this.story.message,
        this.data.title= this.story.title,
        this.data.email= this.story.email ? this.story.email : "" ,
        this.data.service_id= this.story.service_id ? this.story.service_id : "",
        this.data.gym_id= this.story.gym_id ? this.story.gym_id :  "",
        this.time = this.story.shedule_time,
        this.date = this.story.shedule_date,
        // this.image_src_1= this.domain + this.story.image,
        this.data.notification_type= this.story.type
      }
    },
    props:{
      story:{
        type:Object,
        default:null
      },
      edit:{
        type:Boolean
      },
      domain:String
    }
  }
  </script>
  
  <style scoped>
    .img-properties {
      width: 100%;
      max-width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
      border-radius: 0.25rem;
      margin: 0 auto;
      display: block;
      max-height: 400px;
      margin-top: 10px;
    }

    .img-container-1{
      position: relative;
      width: 100%;
    }

    .img-container-2{
      position: relative;
      width: 100%;
    }

    .main-container{
      width: 100%;
    }
    .main-img-container{
      background-color: #BBDEFB;
      border-radius: 10px;
      min-height: 15vw;
    }

    .img-container{
      width: 100%;
      display: flex;
      
    }

    .upload-lable{
      position: absolute;
      top: 1rem; /* You can adjust the top margin as needed */
      margin-left: 1rem; /* You can adjust the left margin as needed */
      z-index: 1;
    }
  </style>
  