import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c('span',{staticClass:"text-h5"},[_vm._v("Manage Ads gallery")]),_c(VSpacer),_c(VBtn,{attrs:{"large":"","rounded":"","elevation":"0","color":"black","dark":""},on:{"click":function($event){_vm.addANewAdItem=true}}},[_vm._v("Add A New Add")])],1),_c(VDivider,{staticClass:"my-4"}),_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.ads),function(ad,i){return _c(VCard,{key:i,staticClass:"ma-2 rounded-lg",attrs:{"width":"40%","hover":"","ripple":""}},[_c(VImg,{attrs:{"src":_vm.$store.state.s3ResourcesBaseUrl + ad.image}}),_c(VCardActions,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VBtn,{attrs:{"href":ad.link,"text":""}},[_vm._v("Visit Link")]),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.deleteAd(ad)}}},[_vm._v("Delete Ad")])],1)],1)],1)}),1),(_vm.addANewAdItem)?_c(VBottomSheet,{attrs:{"inset":"","persistent":""},model:{value:(_vm.addANewAdItem),callback:function ($$v) {_vm.addANewAdItem=$$v},expression:"addANewAdItem"}},[_c('AddNewAd',{on:{"close":_vm.close}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }