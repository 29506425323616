<template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <span class="headline">{{ edit ? 'Edit':'Add' }} Preset</span>
          <v-spacer/>
          <v-btn @click="$emit('close')" icon color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider class="mb-4"/>
      <v-card-text>
        <form @submit.prevent="saveOrUpdate()">
          <v-row  class="mb-n4">
              <v-col
                cols="12"
                sm="9"
              >
              <span class="err-msg" v-if="v$.title.$error">{{ v$.title.$errors[0].$message }}</span>
              <v-text-field  outlined  label="Title"  v-model="state.title"></v-text-field>
            </v-col> 
         
            <v-col
                cols="12"
                sm="3"
              >
              <span class="err-msg" v-if="v$.title.$error">{{ v$.title.$errors[0].$message }}</span>
              <v-text-field  outlined  label="Days Count" max="10" min="1" type="number"  required v-model="state.days"></v-text-field>
            </v-col> 

            <v-col
                cols="12"
              >
              <span class="err-msg" v-if="v$.description.$error">{{ v$.description.$errors[0].$message }}</span>
              <v-textarea  v-model="state.description" class="mx-2 mb-3" label="Description" rows="3" prepend-icon="mdi-comment"></v-textarea>
            </v-col>
            
          </v-row>
        
          <v-row >
            <v-col
                cols="12"
              >
              <ExcercisePresetAdd :edited="isEdit" :days_count ="state.days" @qualification-emitted="handleValueQualification($event)" :feild_data="qualification_arr"  />
            </v-col>
            
          </v-row>
          <v-row justify="end" no-gutters>
            <v-btn type="submit" :loading="loading"  width="128" elevation="0"  height="56" color="primary">Save</v-btn>
          </v-row>
      </form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import useValidate from '@vuelidate/core'
  import { required,numeric, maxLength, maxValue , minValue } from '@vuelidate/validators'
  import { reactive, computed } from "vue";
  import ExcercisePresetAdd from "@/components/ExcercisePresetAdd.vue";

  export default {
    setup(){
      const state = reactive({
        title: "",
        days:1,
        description:""
      })

      const rules = computed(()=>{
        return { 
            title: { required ,maxLength:maxLength(200)},
            days: { required ,numeric, maxValue:maxValue(10), minValue:minValue(1)},
            description:{required, maxLength:maxLength(2000) }
          }
      })

      
      const v$ =  useValidate(rules, state)

      return {
        v$,
        state
      }
    },
    name: "AddPreset",
    components: {ExcercisePresetAdd},
    props: {
      edit: Boolean,
      story: Object,
      domain:String
    },
    data: () => ({
      selectedOption:"0",
      loading: false,
      qualification_arr:[],
      isEdit:false,
      workout_id:null,
    }),
    methods:{
      handleValueQualification(value){
        this.qualification_arr = value
      },
      submitForm(){

        if( this.qualification_arr.length === 0){
          this.$toast.error({
                  title: 'error',
                  message: "All Feilds Required!",
            });
            this.loading = false;
            return;
        }

        this.v$.$validate()
        if (!this.v$.$error) {
          this.loading = true;

          authClient.post('/fitness/workout-presets/add-or-update',{
            "workout_id": this.workout_id != null ? this.workout_id : undefined,
            "trainer_id":this.$store.state.user.id,
            "workout_plan":JSON.stringify(this.qualification_arr),
            "title":this.state.title,
            "description":this.state.description,
            "day_count" : this.state.days
          })
          .then((response) => {
              this.$emit('close');
              this.loading = false;
              // this.loadData()
              let response_data = response.data
              if(response_data.success){
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      
          }else{
            this.loading = false
          }
      },
      saveOrUpdate(){
        this.loading = true;
        if(this.edit) {
          this.submitForm();
        } else {
          this.submitForm();
        }
      }
    },
    mounted() {
      if(this.edit){
        this.isEdit = true;
        this.workout_id = this.story.id,
        this.state.title = this.story.title;
        this.state.description = this.story.description;
        this.qualification_arr = this.story.workout_plan
        this.state.days = this.story.day_count
      }
    }
  }
  </script>
  
  
  <style scoped>

.img-properties {
      width: 100%;
      max-width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
      border-radius: 0.25rem;
      margin: 0 auto;
      display: block;
      max-height: 400px;
      margin-top: 10px;
    }

    .img-container-1{
      position: relative;
      width: 100%;
    }

    .img-container-2{
      position: relative;
      width: 100%;
    }

    .main-container{
      width: 100%;
    }
    .main-img-container{
      background-color: #BBDEFB;
      border-radius: 10px;
      min-height: 15vw;
    }

    .img-container{
      width: 100%;
      display: flex;
      
    }

    .upload-lable{
      position: absolute;
      top: 1rem; /* You can adjust the top margin as needed */
      margin-left: 1rem; /* You can adjust the left margin as needed */
      z-index: 1;
    }

    .err-msg{
      color: red;
    }

    .loading-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99999999;
    }

    .d-none{
      display: none;
    }


  </style>
  