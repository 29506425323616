<template>
  <v-container>
    <v-card outlined elevation="0">
      <v-card-title class="d-flex justify-space-between">
        <template>
        <span class="ml-2"><v-icon>mdi-wallet</v-icon>e-gift Transactions History</span>
        </template>
        <v-btn v-if="isShowTopup"
                color="primary"
                dark
                class="mb-2 self-align-end "
                @click="topupDialog = true"
              >
              e-gift Manage 
              </v-btn>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="transactions"
          :loading="loading"
          :items-per-page="10"
          class="elevation-1">
        <template v-slot:item.created_at="{ item }">
          {{  new Date(item.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZone: 'UTC'
                })
              }}
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip dark :color="item.type === 'Credit' ? 'green' : 'red'">{{ item.type }}</v-chip>
        </template>
        <template v-slot:item.amount="{ item }">
          <span v-if="item.type === 'Credit'">+{{ item.amount }}</span>
          <span v-else>-{{ item.amount }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-bottom-sheet scrollable  inset v-model="topupDialog" >
        <TopupDialog :data="user_id" @soft-close="topupDialog = false;" @close="close"/>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import TopupDialog from "@/components/TopupDialog";

export default {
  components: {TopupDialog},
  name: "UserEWalletTransactions",
  props:{
    user_id:{
      required: true
    },
    isNotTopup:{
      required: false
    }
  },
  data: () => ({
    isShowTopup: true,
    topupDialog: false,
    transactions: [],
    loading: true,
    headers: [
      { text: 'Date', value: 'created_at' },
      { text: 'Amount', value: 'amount' },
      { text: 'Type', value: 'type' },
      { text: 'Reason', value: 'description' },
    ],
  }),
  methods:{
    getTransactions(){
      this.loading = true;
      authClient.post('/wallet/get-user-history',{
        userID:this.user_id
      }).then(value => {
        this.transactions = value.data;
        console.log(value.data);
        this.loading = false;
      });
    },
    close(){
      this.getTransactions()
      this.topupDialog = false;
      this.$emit('close');
    }
  },
  mounted() {
    this.getTransactions();
    if(this.isNotTopup){
      this.isShowTopup = false
    }
  }
}
</script>

<style scoped>

</style>
