import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"mb-4"},[_c(VCardTitle,[_vm._v("Add Qualifications")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c(VTextarea,{staticClass:"mx-2",attrs:{"label":"Qualification","rows":"2"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c(VBtn,{on:{"click":_vm.addField}},[_vm._v("Add Field")])],1)],1),_vm._l((_vm.fields),function(field,index){return _c(VCard,{key:index,staticClass:"mb-4"},[_c(VCardTitle,[_vm._v(" "+_vm._s(field.title)+" "),_c(VSpacer),_c(VBtn,{on:{"click":function($event){return _vm.editField(index)}}},[_vm._v("Edit")]),_c(VBtn,{on:{"click":function($event){return _vm.deleteField(index)}}},[_vm._v("Delete")])],1),_c(VCardText,[_vm._v(" "+_vm._s(field.description)+" ")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }