<template>
  <v-container>
    <v-row no-gutters align="center">
      <span class="text-h5">Manage Coupons Plans</span>
      <v-spacer/>
      <v-btn large rounded elevation="0" color="black" dark @click="upsertASubscription=true">Add A Plan</v-btn>
    </v-row>
    <v-divider class="my-4"/>

    <v-row no-gutters>
      <v-card class="ma-2 rounded-lg" width="40%" hover ripple v-for="(plan,i) in plans" :key="i">
        <v-card-body @click="edit(plan)">
            
        <v-card-title>
          {{ plan.name }}
          <v-spacer/>
          <v-chip color="amber"  dark>{{ plan.discount_percentage }}% Off Active</v-chip>
        </v-card-title>
        <v-card-subtitle>{{ plan.description }}</v-card-subtitle>
        <v-card-title>
          <span class="mr-2" >
            Max Saving{{ plan.max_value.toFixed(2) }}
          </span>
        </v-card-title>
        </v-card-body>
        <v-card-actions>
          <v-row no-gutters>
            <v-btn @click="showDeleteConfirmation(plan, deletePlan)" color="red" text>Delete Coupon</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-row>

    <v-bottom-sheet scrollable inset persistent v-if="upsertASubscription" v-model="upsertASubscription">
      <UpsertCoupon :plan="editing" @close="close"/>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import UpsertCoupon from "@/components/UpsertCoupon";

export default {
  name: "CoupnCreation",
  components: {UpsertCoupon},
  data: ()=>({
    search: '',
    plans: [],
    loading: true,
    upsertASubscription: false,
    editing: null
  }),
  methods: {
    deletePlan(item) {
          authClient.post('delete',{
            tableId: 7,
            resultId: item.id
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getPlans()
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
    getDiscountedPrice(plan){
      if(plan.discounted){
        return (plan.price - (plan.price * (plan.discounted_percentage/100))).toFixed(2);
      } else {
        return plan.price.toFixed(2);
      }
    },
    edit(plan){
      console.log(plan);
      this.editing = plan;
      this.upsertASubscription = true;
    },
    close(){
      this.upsertASubscription = false;
      this.editing = null;
      this.getPlans();
    },
    getPlans(){
      this.loading = true;
      authClient.get('/coupon/actions/list').then(res=>{
        this.plans = res.data;
        console.log(this.plans);
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
    },
  },
  mounted() {
    this.getPlans();
  }
}
</script>

<style scoped>

</style>
