<template>
    <v-card class="rounded-t-lg rounded-b-0">
      <v-card-title>
        <v-row no-gutters>
             Update Body Measurements
          <v-spacer/>
          <v-btn color="red" icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="sm-6">
              <v-text-field v-model="bust" type="number" placeholder="Bust (cm)" :rules="[rules.required]" outlined label="Bust (cm)" />
              <v-text-field v-model="chest" type="number" placeholder="Chest (cm)" :rules="[rules.required]" outlined label="Chest (cm)"/>
            </v-col>
            <v-col cols="sm-6">
              <v-text-field v-model="stomach" type="number" placeholder="Stomach (cm)" :rules="[rules.required]" outlined label="Stomach (cm)"/>
              <v-text-field v-model="calvas" type="number" placeholder="Calves (cm)" :rules="[rules.required]" outlined label="Calves (cm)"/>
            </v-col>
            <v-col cols="sm-6">
              <v-text-field v-model="hips" type="number" placeholder="Hips (cm)" :rules="[rules.required]" outlined label="Hips (cm)" />
              <v-text-field v-model="leftArm" type="number" placeholder="Left Arm (cm)" :rules="[rules.required]" outlined label="Left Arm (cm)"/>
            </v-col>
            <v-col cols="sm-6">
              <v-text-field v-model="thighs" type="number" placeholder="Thighs (cm)" :rules="[rules.required]" outlined label="Thighs (cm)"/>
              <v-text-field v-model="rightArm" type="number" placeholder="Right Arm (cm)" :rules="[rules.required]" outlined label="Right Arm (cm)"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
  
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="upsertPlan" dark large color="primary">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "UpdateMeasurements",
    data: () => ({
      rules: {
      required: value => !!value || 'Required.',
      },
      bust:"",
      stomach:"",
      chest:"",
      hips:"",
      calvas:"",
      thighs:"",
      leftArm:"",
      rightArm:""
    ,
    valid:false,
      editSheet: false,
      userWeight:"",
      userHeight:"",
    }),
    methods:{
      close(){
        this.$emit('close')
      },
      upsertPlan(){
      this.$refs.form.validate();
      if(this.valid){
        this.loading = true;
        authClient.post(`/fitness/gym/${this.data}/misc`, 
            {
              "bust": this.bust, 
              "stomach":this.stomach,
              "chest": this.chest, 
              "calves":this.calvas,
              "hips": this.hips, 
              "thighs": this.thighs, 
              "l_arm":this.leftArm,
              "r_arm":this.rightArm
            }
          ).then(res=>{
          let response_data = res.data
              if(response_data.success){
                this.close();
                this.$toast.success({
                  title: 'Ok',
                  message: 'success',
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.error,
              });
              }
        }).catch(err=>{
          console.log(err);
        }).finally(()=>{
          this.loading = false;
        })
      }
    },
    },
    mounted() {
        this.bust = this.Measurement_Data.bust, 
        this.stomach  = this.Measurement_Data.stomach,
        this.chest = this.Measurement_Data.chest, 
        this.calvas = this.Measurement_Data.calves,
        this.hips = this.Measurement_Data.hips, 
        this.thighs = this.Measurement_Data.thighs, 
        this.leftArm = this.Measurement_Data.l_arm,
        this.rightArm = this.Measurement_Data.r_arm
    },
    props: {
    data: {
        required: true
      },
      Measurement_Data: {
        required: true
      },
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  