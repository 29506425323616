<template>
    <v-container>
      <v-card outlined elevation="0">
        <v-card-title class="d-flex justify-space-between">
          <template>
          <span class="ml-2"><v-icon>mdi-water-alert-outline</v-icon> Blood Sugar</span>
          </template>
          <v-btn v-if="isShowTopup"
                  color="primary"
                  dark
                  class="mb-2 self-align-end"
                  @click="updateForm = true"
                >
                  Update 
                </v-btn>
        </v-card-title>
        
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                      <th class="text-left">
                        Value (mg/dl)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    >
                      <td>Fasting Blood Sugar</td>
                      <td>{{  HelthData.fbs_category }}</td>
                      <td>{{   HelthData.fasting_blood_sugar }}</td>
                    </tr>
                    <tr
                    >
                      <td>Post Blood Sugar</td>
                      <td>{{  HelthData.rbs_category }}</td>
                      <td>{{  HelthData.random_blood_sugar}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
           
      </v-card>
  
      <v-bottom-sheet scrollable  inset v-model="updateForm" >
          <UpdateBloodSugar :data="user_id" :BloodSugar_Data="HelthData" @soft-close="updateForm = false;" @close="close"/>
      </v-bottom-sheet>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import UpdateBloodSugar from "@/components/UpdateBloodSugar";
  
  export default {
    components: {UpdateBloodSugar},
    name: "UserBloodSuger",
    props:{
      user_id:{
        required: true
      },
      HelthData:{
        type: Object,
        required: true
      }
    },
    data: () => ({
      isShowTopup: true,
      updateForm: false,
      loading: true,
      headers: [
        { text: 'Type', value: 'created_at' },
        { text: 'Range', value: 'amount' },
        { text: 'Value', value: 'type' },
      ],
    }),
    methods:{
      close(){
        this.updateForm = false;
        this.$emit('close');
        this.$emit('render');
      }
    },
    mounted() {
      if(this.isNotTopup){
        this.isShowTopup = false
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  