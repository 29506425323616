import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c(VImg,{staticClass:"rounded-xl",attrs:{"max-height":"128","min-width":"128","min-height":"128","max-width":"128","src":_vm.$store.state.s3BaseUrl + _vm.data.avatar_url}}),_c(VCol,{staticClass:"ml-8"},[_c('span',{staticClass:"text-h4 font-weight-bold"},[_vm._v(_vm._s(_vm.data.name))]),_c('br')]),_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"elevation":"0","dark":"","rounded":"","color":"#ffa200"},on:{"click":function($event){_vm.addProUser = true}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v("Add Workout ")],1)],1),(_vm.addProUser)?_c(VBottomSheet,{attrs:{"inset":""},model:{value:(_vm.addProUser),callback:function ($$v) {_vm.addProUser=$$v},expression:"addProUser"}},[_c('AddWorkout',{attrs:{"data":_vm.data},on:{"close":function($event){return _vm.close()}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }