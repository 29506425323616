<template>
  <v-container>
    <v-row no-gutters>
      <v-text-field v-model="search" outlined rounded label="Search Resources..."></v-text-field>
      <v-btn to="/resources/add-resources" class="ml-2" rounded height="56" elevation="0" dark>
        <v-icon>mdi-plus</v-icon>
        Add A Resource
      </v-btn>
    </v-row>
    <v-card outlined>
      <v-data-table
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="resources">

        <template v-slot:item.image="{ item }">
          <v-btn target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.image" outlined rounded>
            View
          </v-btn>
        </template>

        <template v-slot:item.article="{ item }">
          <v-btn @click="previewHTML(item.article)" outlined rounded>
            Preview
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn @click="editRes(item)" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteResource(item)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "Resources",
  data: () => ({
    loading: true,
    addFoodDialog: false,
    isEdit: false,
    editFood: null,
    resources: [],
    headers: [
      {text: "ID", value: "id"},
      {text: "Title", value: "title"},
      {text: "Category", value: "category"},
      {text: "Description", value: "description"},
      {text: "Article", value: "article"},
      {text: "Image", value: "image"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    search: '',
  }),
  methods: {
    editRes(Res){
      this.$router.push({name: 'Add Resource', params: {resource: Res}})
    },
    previewHTML(html){
      let previewOpen = window.open("", "previewOpen", "width=320, height=720");
      previewOpen.document.body.innerHTML = html;
    },
    deleteResource(Res) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', (instance, toast)=> {
              authClient.post('/fitness/resources/actions/delete', {
                id: Res.id
              }).then(async () => {
                await authClient.post('/users/file-uploads/delete-file', {
                  'filePath': Res.image
                });
                this.getResources();
                this.closeDialogs();
              });
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
    getResources() {
      authClient.get('/fitness/resources/actions/get').then((response) => {
        this.resources = response.data;
        console.log(response);
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    closeDialogs() {
      this.addFoodDialog = false;
      this.getResources();
    }
  },
  mounted() {
    this.getResources();
  }
}
</script>

<style scoped>

</style>
