<template>
    <v-card class="rounded-t-lg rounded-b-0">
      <v-card-title>
        <v-row no-gutters>
             Topup
          <v-spacer/>
          <v-btn color="red" icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-select :items="topupType" item-text="text" item-value="value" v-model="increment_type" :rules="[rules.required]" outlined label="Increment Type"/>
          <v-text-field v-model="reason" :rules="[rules.required]" outlined label="Reason"/>
          <v-text-field v-model="increment_price" :rules="[rules.required]" outlined label="Price (MVR)"/>
        </v-form>
      </v-card-text>
  
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="upsertPlan" dark large color="primary">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "TopupDialog",
    data: () => ({
      rules: {
      required: value => !!value || 'Required.',
    },
    topupType: [
      {text: 'credit', value: '1'},
      {text: 'debit', value: '2'},
    ],
    valid:false,
      editSheet: false,
      plans: [],
      reason:"",
      increment_price:0,
      increment_type:"1",
      current_subscription_id: 0,
      gender:[
        {text:'Male',value:'male'},
        {text:'Female',value:'female'}
      ]
    }),
    methods:{
      close(){
        this.$emit('close')
      },
      upsertPlan(){
      this.$refs.form.validate();
      if(this.valid){
        this.loading = true;
        authClient.post('/wallet/admin/topup', {
            "user_id":this.data, 
            "amount":this.increment_price, 
            "increment_type":this.increment_type, 
            "reason":this.reason
          }).then(res=>{
          let response_data = res.data[0]
              if(response_data.status){
                this.$emit('close');
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
        }).catch(err=>{
          console.log(err);
        }).finally(()=>{
          this.loading = false;
        })
      }
    },
    },
    mounted() {
      // this.getPlans()
      // this.editData = this.data;
      // this.editData.nic = this.editData.nic.toUpperCase();
      // console.log(this.editData);
    },
    props: {
      data: {
        required: true
      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  