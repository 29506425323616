import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VBtn,{staticClass:"ma-2",attrs:{"outlined":"","large":"","fab":""},on:{"click":function($event){return _vm.viewDetails()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.workout_plan),function(plan,i){return _c(VCard,{key:i,staticClass:"ma-2 rounded-lg",attrs:{"width":"40%","hover":"","ripple":""}},[_c(VImg,{attrs:{"src":_vm.$store.state.s3ResourcesBaseUrl + plan.animation_url}}),_c('v-card-body',{on:{"click":function($event){return _vm.edit(plan)}}},[_c(VCardTitle,[_vm._v(" "+_vm._s(plan.title)+" "),_c(VSpacer)],1),_c(VRow,{staticClass:"d-flex justify-center"},[_c(VChip,{staticClass:"ma-2 rounded-lg text-center"},[_vm._v(" REPETITIONS : "+_vm._s(plan.repetitions)+" ")]),_c(VChip,{staticClass:"ma-2 rounded-lg"},[_vm._v(" Sets : "+_vm._s(plan.sets)+" ")]),_c(VChip,{staticClass:"ma-2 rounded-lg"},[_vm._v(" Weight: "+_vm._s(plan.weight)+" Kg ")])],1),_c(VCardTitle,[_c('span',{staticClass:"mr-2"})])],1)],1)}),1),(_vm.upsertASubscription)?_c(VBottomSheet,{attrs:{"scrollable":"","inset":"","persistent":""},model:{value:(_vm.upsertASubscription),callback:function ($$v) {_vm.upsertASubscription=$$v},expression:"upsertASubscription"}},[_c('UpsertCoupon',{attrs:{"plan":_vm.editing},on:{"close":_vm.close}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }