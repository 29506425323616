import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{class:_vm.color_type == 1 ? 'pa-4 text-end green-background' : 'pa-4 text-end yellow-background'},[_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c(VIcon,{staticClass:"icon-img pr-4",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icon_img))])],1),_c(VCardSubtitle,{staticClass:"text-start dark-gray--text display-count"},[_vm._v(_vm._s(_vm.count))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }