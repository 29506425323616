import { render, staticRenderFns } from "./UpdateBloodPressure.vue?vue&type=template&id=ea4cf808&scoped=true&"
import script from "./UpdateBloodPressure.vue?vue&type=script&lang=js&"
export * from "./UpdateBloodPressure.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea4cf808",
  null
  
)

export default component.exports