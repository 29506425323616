<template>
    <div>
      <v-row align="center" no-gutters>
        <v-img class="rounded-xl" max-height="128" min-width="128" min-height="128" max-width="128" :src=" $store.state.s3BaseUrl + data.avatar_url"></v-img>
        <v-col class="ml-8">
          <span class="text-h4 font-weight-bold">{{ data.name }}</span><br>
        </v-col>
        <v-spacer/>
  
        <v-btn class="mr-2"  @click="addProUser = true" elevation="0" dark rounded color="#ffa200">
          <v-icon class="mr-2">mdi-plus</v-icon>Add Workout
        </v-btn>

      </v-row>
      <v-bottom-sheet v-if="addProUser" inset v-model="addProUser">
        <AddWorkout :data="data" @close="close()"/>
      </v-bottom-sheet>

    </div>
  </template>
  
  <script>

  import AddWorkout from "@/components/AddWorkout";
  export default {
    name: "GymUserDetailsHeading",
    components: {AddWorkout},
    computed : {
      checkActiveSub(){
        if(this.subscription == null){
            return false;
          } else {
            return this.subscription.is_active
          }
      }
    },
    data: () => ({
      hasActiveSub: false,
      addProUser: false,
    }),
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    methods:{
      close(){
        this.addProUser = false;
        this.$emit('close');
      }
    },
    mounted() {
      
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  