import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"outlined":"","rounded":"","label":"Search Resources..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VBtn,{staticClass:"ml-2",attrs:{"to":"/resources/add-resources","rounded":"","height":"56","elevation":"0","dark":""}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Add A Resource ")],1)],1),_c(VCard,{attrs:{"outlined":""}},[_c(VDataTable,{attrs:{"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.resources},scopedSlots:_vm._u([{key:"item.image",fn:function({ item }){return [_c(VBtn,{attrs:{"target":"_blank","href":_vm.$store.state.s3ResourcesBaseUrl  + item.image,"outlined":"","rounded":""}},[_vm._v(" View ")])]}},{key:"item.article",fn:function({ item }){return [_c(VBtn,{attrs:{"outlined":"","rounded":""},on:{"click":function($event){return _vm.previewHTML(item.article)}}},[_vm._v(" Preview ")])]}},{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.editRes(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteResource(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }