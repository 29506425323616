<template>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <span class="headline">{{ edit ? 'Edit':'Add' }} Plan</span>
          <v-spacer/>
          <v-btn @click="$emit('close')" icon color="red">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-divider class="mb-4"/>
      <v-card-text>
        <form @submit.prevent="saveOrUpdate()">
          <v-row no-gutters>
              <v-col
                cols="12"
              >
              <span class="err-msg" v-if="v$.name.$error">{{ v$.name.$errors[0].$message }}</span>
              <v-text-field  outlined  label="Plan Name" class="mr-2" v-model="state.name"></v-text-field>
              
            </v-col> 
          </v-row>
          <v-row no-gutters>
              <v-col
                cols="12"
                sm="6"
              >
              <span class="err-msg" v-if="v$.duration_amount.$error">{{ v$.duration_amount.$errors[0].$message }}</span>
              <v-text-field  outlined type="number"  label="Duration (days)" class="mr-2" v-model="state.duration_amount"></v-text-field>
              
            </v-col> 
            <v-col
                sm="6"
              >
              <span class="err-msg" v-if="v$.price.$error">{{ v$.price.$errors[0].$message }}</span>
              <v-text-field  outlined type="number"  label="Charge" class="mr-2" v-model="state.price"></v-text-field>
            </v-col> 
          </v-row>
          <v-switch v-if="state.price>0" class="ml-3" label="Discounted" inset v-model="state.discounted"></v-switch>
          <v-row no-gutters v-if="state.discounted == 1">
              <v-col
                cols="12"
                sm="12"
              >
              <span class="err-msg" v-if="v$.discounted_percentage.$error">{{ v$.discounted_percentage.$errors[0].$message }}</span>
              <v-text-field  outlined  label="Discount" class="mr-1" v-model="state.discounted_percentage"></v-text-field>
            </v-col> 
          </v-row>

        <v-row justify="end" no-gutters>
          <v-btn type="submit" :loading="loading"  width="128" elevation="0"  height="56" color="primary">Save</v-btn>
        </v-row>
      </form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import useValidate from '@vuelidate/core'
  import { required,numeric, maxLength } from '@vuelidate/validators'
  import { reactive, computed } from "vue";


  export default {
    setup(){
      const state = reactive({
        name: "",
        price:0,
        duration_amount:0,
        discounted:0,
        discounted_percentage:0,
      })

      const rules = computed(()=>{
        return { 
            name: { required, maxLength:maxLength(200) },
            price: { numeric, required },
            discounted_percentage: { required , numeric},
            discounted: {  required},
            duration_amount: { required , numeric},
          }
      })

      
      const v$ =  useValidate(rules, state)

      return {
        v$,
        state
      }
    },
    name: "AddSiteAd",
    props: {
      edit: Boolean,
      plan:Object,
      gym_id:Number,
    },
    data: () => ({
      loading: false,
      title: '',
      plan_id : null,
    }),
    methods:{
      submitForm(){
        this.v$.$validate()
        if (!this.v$.$error) {
          this.loading = true;

          authClient.post('/commercial-gym/plan/add',{
            'id':this.plan_id,
            'gym_id':this.gym_id,
            'name':this.state.name,
            'price' : this.state.price,
            'duration_amount': this.state.duration_amount,
            'discounted' : this.state.discounted,
            'discounted_percentage':this.state.discounted_percentage
          })
          .then((response) => {
              this.$emit('close');
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      
          }else{
            this.loading = false
          }
      },
      // saveExercise(){
      //   if(this.title !== '' && this.description !== '' && this.animation !== null && this.preview !== null){
      //     this.loading = true;
      //     let formData = new FormData();
      //     formData.append('animation', this.animation);
      //     formData.append('preview', this.preview);
      //     authClient.post('/users/file-uploads/exercise-files',formData).then((response) => {
      //       authClient.post('/fitness/workouts/actions/new',{
      //         title: this.title,
      //         description: this.description,
      //         categories: this.categories,
      //         animation_url: response.data.animation,
      //         preview_animation_url: response.data.preview,
      //         optional: this.optional
      //       }).then((response) => {
      //         this.loading = false;
      //         console.log(response);
      //         this.$emit('close');
      //       }).catch((error) => {
      //         console.log(error);
      //         this.loading = false;
      //       });
      //     }).catch((error) => {
      //       console.log(error);
      //       this.loading = false;
      //     });
      //   } else {
      //     window.alert('Please fill all the fields');
      //     this.loading = false;
      //   }
      // },
      saveOrUpdate(){
        this.loading = true;
        if(this.edit) {
          this.submitForm();
        } else {
          this.submitForm();
        }
      }
    },
    mounted() {
      if(this.edit){
        this.state.name = this.plan.name;
        this.state.price =  this.plan.price;
        this.state.discounted =  this.plan.discounted;
        this.state.discounted_percentage =   this.plan.discounted_percentage;
        this.state.duration_amount =   this.plan.duration_amount;
        this.plan_id = this.plan.id
      }
    }
  }
  </script>
  
  
  <style scoped>

.img-properties {
      width: 100%;
      max-width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
      border-radius: 0.25rem;
      margin: 0 auto;
      display: block;
      max-height: 400px;
      margin-top: 10px;
    }

    .img-container-1{
      position: relative;
      width: 100%;
    }

    .img-container-2{
      position: relative;
      width: 100%;
    }

    .main-container{
      width: 100%;
    }
    .main-img-container{
      background-color: #BBDEFB;
      border-radius: 10px;
      min-height: 15vw;
    }

    .img-container{
      width: 100%;
      display: flex;
      
    }

    .upload-lable{
      position: absolute;
      top: 1rem; /* You can adjust the top margin as needed */
      margin-left: 1rem; /* You can adjust the left margin as needed */
      z-index: 1;
    }

    .err-msg{
      color: red;
    }

    .loading-backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99999999;
    }

    .d-none{
      display: none;
    }


  </style>
  