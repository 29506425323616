import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"outlined":"","elevation":"0"}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[[_c('span',{staticClass:"ml-2"},[_c(VIcon,[_vm._v("mdi-water-alert-outline")]),_vm._v(" Blood Sugar")],1)],(_vm.isShowTopup)?_c(VBtn,{staticClass:"mb-2 self-align-end",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.updateForm = true}}},[_vm._v(" Update ")]):_vm._e()],2),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Status ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Value (mg/dl) ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Fasting Blood Sugar")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.fbs_category))]),_c('td',[_vm._v(_vm._s(_vm.HelthData.fasting_blood_sugar))])]),_c('tr',[_c('td',[_vm._v("Post Blood Sugar")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.rbs_category))]),_c('td',[_vm._v(_vm._s(_vm.HelthData.random_blood_sugar))])])])]},proxy:true}])})],1),_c(VBottomSheet,{attrs:{"scrollable":"","inset":""},model:{value:(_vm.updateForm),callback:function ($$v) {_vm.updateForm=$$v},expression:"updateForm"}},[_c('UpdateBloodSugar',{attrs:{"data":_vm.user_id,"BloodSugar_Data":_vm.HelthData},on:{"soft-close":function($event){_vm.updateForm = false;},"close":_vm.close}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }