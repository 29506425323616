<template>
    <v-container>
      <v-row no-gutters>
        <v-text-field v-model="search" outlined rounded label="Search User..."></v-text-field>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="stories">
          <template v-slot:item.description="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.img_path="{ item }">
            <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.img_path">
              View
            </v-btn>
          </template>
  
        </v-data-table>
      </v-card>

  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog max-width="768" persistent v-if="optionalViewDialog" v-model="optionalViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Basic Info</span>
              <v-spacer/>
              <v-btn @click="optionalViewDialog = false; basicInfo=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text class="basic-data">
            <template>
              N/A
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import SuccessStoryDialog from "@/components/SuccessStoryDialog.vue";
  
  export default {
    name: "UserFaq",
    components: {SuccessStoryDialog},
    data: () => ({
      loading: false,
      domain:'',
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      basicInfo: null,
      isEdit: false,
      editStory: null,
      stories: [],
      headers: [
        {text: "ID", value: "id"},
        {text: "Main Category", value: "main_faq.title"},
        {text: "Sub Category", value: "sub_faq.title"},
        {text: "User Name", value: "user.name"},
        {text: "User Email", value: "user.email"},
        {text: "Message", value: "description"},
        {text: "Attachements", value: "img_path"},
      ],
      search: '',
    }),
    methods: {
      deleteStory(item) {
          authClient.post('delete',{
            tableId: 2,
            resultId: item.id
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getStories()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.before_img
            }).then((response) => {
              authClient.post('/users/file-uploads/delete-file',{
                filePath: item.after_img,
              }).then((response) => {
                
              }).catch((error) => {
                console.log(error);
              });
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      statusChange(id, status) {
        authClient.post('/status/change',{
            status: status,
            tableId: 2,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getStories()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
      },
      getAppintments() {
        authClient.get('/faq/user/contact/list').then((response) => {
          let data = response.data
          this.stories = data;
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getAppintments();
      }
    },
    mounted() {
      this.getAppintments();
    }
  }
  </script>
  
  <style scoped>
    
    .basic-data{
        font-size: medium;
        color: black !important;
    }

  </style>
  