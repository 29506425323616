<template>
  <v-card>
    <v-card-title>
      {{ this.plan !== null ? 'Edit' : 'Add' }} a Subscription
      <v-spacer/>
      <v-btn color="red" icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field v-model="data.name" :rules="[rules.required]" outlined label="Plan Name"/>
        <v-select :items="subTypes" v-model="data.user_role" item-text="text" item-value="value"  :rules="[rules.required]" outlined label="Subscription Type"/>
        <v-text-field v-model="data.description" :rules="[rules.required]" outlined label="Plan Description"/>
        <v-text-field v-model="data.price" :rules="[rules.required]" outlined label="Price (MVR)"/>
        <v-row no-gutters>
          <v-select :items="durationUnits" item-text="text" item-value="value" v-model="data.duration_unit" :rules="[rules.required]" outlined label="Duration Unit"/>
          <v-text-field type="number" min="1" v-if="data.duration_unit !=='lifetime'" v-model="data.duration_amount" :rules="[rules.required]" outlined label="Duration" class="pl-2"/>
        </v-row>


        <v-switch class="ml-3" label="Discounted" inset v-model="data.discounted"></v-switch>
        <!-- <template v-if="data.discounted">
          <v-select :items="discount_types" item-text="text" item-value="value" v-model="data.discount_type" :rules="[rules.required]" outlined label="Discount Type" class="pl-2"/>
          <v-text-field v-if="data.discount_type == 1" append-icon="mdi-percent" type="number" min="0" max="100" v-model="data.discounted_percentage" :rules="[rules.required]" outlined label="Discounted Percentage" class="pl-2"/>
          <v-text-field v-if="data.discount_type == 2"  type="number" min="0" v-model="data.flat_rate" :rules="[rules.required]" outlined label="Flat Rate" class="pl-2"/>
          <v-text-field  type="number" min="0" v-model="data.max_saving" :rules="[rules.required]" outlined label="Max Saving" class="pl-2"/>
        </template> -->

        <template v-if="data.discounted">
          <v-select :items="discount_types" item-text="text" item-value="value" v-model="data.discount_type" :rules="[rules.required]" outlined label="Discount Type" class="pl-2"/>
          <v-text-field v-if="data.discount_type == 1" append-icon="mdi-percent" type="number" min="0" max="100" v-model="data.discounted_percentage"  outlined label="Discounted Percentage" class="pl-2"/>
          <v-text-field v-if="data.discount_type == 2"  type="number" min="0"  outlined label="Flat Rate" v-model="data.flat_rate" class="pl-2"/>
          <v-text-field  type="number" min="0"  outlined label="Max Saving" class="pl-2" v-model="data.max_saving" />
        </template>
        

      </v-form>
    </v-card-text>


    <v-card-actions style="background: #1976d2">
      <v-spacer/>
      <v-btn @click="upsertPlan" large color="white">
        <v-icon class="mr-2">mdi-check</v-icon>
        Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "UpsertASubscription",
  data:()=>({
    loading:false,
    rules: {
      required: value => !!value || 'Required.',
    },
    durationUnits: [
      {text: 'Months', value: 'month'},
      {text: 'Years', value: 'year'},
      {text: 'Lifetime', value: 'lifetime'},
    ],subTypes: [
      {text: 'For Members', value: '1'},
      {text: 'For Coaches', value: '2'},
    ],
    discount_types: [
      {text: 'Percentage', value: '1'},
      {text: 'Flat Rate', value: '2'},
    ],
    data: {
      name:"",
      user_role:"1",
      description:"",
      price:"",
      duration_amount:0,
      duration_unit:"month", // month, year, lifetime
      discounted:0,
      max_saving:0,
      flat_rate:0,
      discounted_percentage:0,
      id:null,
      discount_type:null,
    },
    valid: false,
  }),
  methods:{
    upsertPlan(){
      this.$refs.form.validate();
      if(this.valid){
        this.loading = true;
        authClient.post('/admin/subscriptions/upsert', this.data).then(res=>{
          console.log(res);
          this.$emit('close');
        }).catch(err=>{
          console.log(err);
        }).finally(()=>{
          this.loading = false;
        })
      }
    },

    close(){
      this.$emit('close');
    }
  },
  mounted() {
    console.log(this.plan);
    if(this.plan !== null){
      this.data = this.plan;
    }
  },
  props:{
    plan:{
      type:Object,
      default:null
    }
  }
}
</script>

<style scoped>

</style>
