import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"outlined":"","elevation":"0"}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[[_c('span',{staticClass:"ml-2"},[_c(VIcon,[_vm._v("mdi-tape-measure")]),_vm._v(" Body Measurements")],1)],(_vm.isShowTopup)?_c(VBtn,{staticClass:"mb-2 self-align-end",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.updateForm = true}}},[_vm._v(" Update ")]):_vm._e()],2),_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Value ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Name ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Value ")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Bust")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.bust))]),_c('td',[_vm._v("Stomach")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.stomach))])]),_c('tr',[_c('td',[_vm._v("Chest")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.chest))]),_c('td',[_vm._v("Calves")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.calves))])]),_c('tr',[_c('td',[_vm._v("Hips")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.hips))]),_c('td',[_vm._v("Thighs")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.thighs))])]),_c('tr',[_c('td',[_vm._v("Left Arm")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.l_arm))]),_c('td',[_vm._v("Right Arm")]),_c('td',[_vm._v(_vm._s(_vm.HelthData.misc_data.r_arm))])])])]},proxy:true}])})],1),_c(VBottomSheet,{attrs:{"scrollable":"","inset":""},model:{value:(_vm.updateForm),callback:function ($$v) {_vm.updateForm=$$v},expression:"updateForm"}},[_c('UpdateMeasurements',{attrs:{"data":_vm.user_id,"Measurement_Data":_vm.HelthData.misc_data},on:{"soft-close":function($event){_vm.updateForm = false;},"close":_vm.close}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }