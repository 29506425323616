<template>
  <v-container>


    <v-row no-gutters>
      <v-text-field v-model="search" outlined rounded label="Search New Medical Professionals..."></v-text-field>
    </v-row>

    <v-card outlined :loading="loading">
      <v-data-table
          :search="search"
          :headers="headers"
          :items="doctors">
        <template v-slot:item.status="{ item }">
          <v-chip dark  color="red">New</v-chip>
        </template>

        <template v-slot:item.ingredients="{ item }">
          <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.ingredients">
            View
          </v-btn>
        </template>

        <template v-slot:item.carbs="{ item }">
            <v-btn  @click="optionalViewDialog=true;basicInfo = item" outlined rounded>
              View
            </v-btn>
        </template>


        <template v-slot:item.actions="{ item }">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     v-on="on" @click="toggleStatus(item)" color="green" icon>
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Approve</span>
          </v-tooltip>

          <v-dialog max-width="768" persistent v-if="optionalViewDialog" v-model="optionalViewDialog">
            <v-card>
              <v-card-title>
                <v-row no-gutters>
                  <span class="headline">Additional Info</span>
                  <v-spacer/>
                  <v-btn @click="optionalViewDialog = false; basicInfo=''" icon color="red">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>
              <v-divider class="mb-4"/>
              <v-card-text class="basic-data">
                <template>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            potion
                          </th>
                          <th class="text-left">
                            Calories
                          </th>
                          <th class="text-left">
                            Carbs
                          </th>
                          <th class="text-left">
                            Fat
                          </th>
                          <th class="text-left">
                            Fibers
                          </th>
                          <th class="text-left">
                            proteins
                          </th>
                          <th class="text-left">
                            Sat. Fat
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                        >
                          <td>{{ basicInfo.potion }}</td>
                          <td>{{ basicInfo.calories }}</td>
                          <td>{{ basicInfo.carbs }}</td>
                          <td>{{ basicInfo.fat }}</td>
                          <td>{{ basicInfo.fibers}}</td>
                          <td>{{ basicInfo.proteins}}</td>
                          <td>{{ basicInfo.sat_fat}}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
          <v-card>
            <v-card-title>
              <v-row no-gutters>
                <span class="headline">Ingredients</span>
                <v-spacer/>
                <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-row>
            </v-card-title>
            <v-divider class="mb-4"/>
            <v-card-text>
              <li v-for="item in selectedDescription" :key="item.id">
                <h3>{{ item.name }}</h3>
                <p><strong>Number of Portions:</strong> {{ item.no_of_potions }}</p>
                <p><strong>Portion Size:</strong> {{ item.potion }}</p>
                <p><strong>Total Calories:</strong> {{ item.total_calories }} kcal</p>
              </li>
            </v-card-text>
          </v-card>
        </v-dialog>

        </template>
      </v-data-table>
    </v-card>


  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "NewDoctors",
  data: () => ({
    loading: true,
    basicInfo:null,
    selectedDescription:"",
    descriptionViewDialog:false,
    optionalViewDialog:false,
    headers: [
      {text: "Id", value: "id"},
      {text: "User Name", value: "userName"},
      {text: "Email", value: "email"},
      {text: "Food Name", value: "name"},
      {text: "Additional Information", value:'carbs'},
      {text: "Ingredients", value:'ingredients'},
      {text: "Status", value: "status"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    search: '',
    doctors:[],
  }),
  methods: {
    viewDetails(item){
      console.log(item);
      this.$router.push({name:'Medical Professionals Profile Overview', params:{member:item}});
    },
    toggleStatus(item) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to Approve?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', (instance, toast)=> {
              this.loading = true;
              authClient.post('/fitness/clients/food/approve', {
                food_id: item.id,
              })
                .then(response => {
                  console.log(response);
                  this.$toast.success({
                    title: 'Ok',
                    message: response,
                });
                  this.getDoctors();
                })
                .catch(error => {
                  console.log(error);
                  this.loading = false;
              });
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
    getDoctors() {
      this.loading = true;
      authClient.get('/fitness/clients/food/new').then(response => {
        this.doctors = response.data;
        this.loading = false;
        console.log(this.doctors);
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getDoctors();
  }
}
</script>

<style scoped>
  ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      border: 1px solid #ddd;
      margin: 10px 0;
      padding: 10px;
      border-radius: 5px;
      background-color: #f9f9f9;
      list-style-type: none;
    }

    h3 {
      margin: 0;
      color: #333;
    }

    p {
      margin: 5px 0;
      color: #555;
    }
</style>
