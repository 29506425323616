<template>
    <v-container>
      <v-row no-gutters>
        <v-text-field v-model="search" outlined rounded label="Search User..."></v-text-field>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="gymUserData">

            <template v-slot:item.gym_trainer_id="{ item }">
              <v-chip dark :color="item.gym_trainer_id  === null ? 'warning':'green'">{{ item.gym_trainer_id === null ? 'No':'Yes' }}</v-chip>
            </template>

          <template v-slot:item.description="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.image="{ item }">
            <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.image">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.ad_url="{ item }">
            <v-btn outlined rounded target="_blank" :href="item.ad_url">
              Visit
            </v-btn>
          </template>

  
          <template v-slot:item.actions="{ item }">
            <v-btn :disabled="item.gym_trainer_id !== null" @click="inviteUser(item)" icon >
              <v-icon>mdi-send</v-icon>
            </v-btn>
            <v-btn v-if="item.gym_trainer_id !== null" @click="viewDetails(item)" v-bind="attrs"
                     v-on="on" icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
        <AddNote :notes_obj='courtesy_Notes' :domain="domain" :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import AddNote from "@/components/AddNote.vue";
  
  export default {
    name: "ComGymUsers",
    components: {AddNote},
    data: () => ({
      loading: true,
      domain:'',
      orderList:[],
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      courtesy_Notes: null,
      gymUserData: [],
      headers: [
        {text: "ID", value: "id"},
        {text: "User Name", value: "name"},
        {text: "Email", value: "email"},
        {text: "Accepted", value: "gym_trainer_id"},
        {text: "Actions", value: "actions"},
      ],
      search: '',
    }),
    methods: {
      deleteItem(item) {
          authClient.post('delete',{
            tableId: 8,
            resultId: item.id
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getGymUsers()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.ad_img
            }).then((response) => {
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      statusChange(id, status) {
        authClient.post('/status/change',{
            status: status,
            tableId: 3,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getGymUsers()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
      },
      getGymUsers() {
        authClient.get('/fitness/com-users/list').then((response) => {
          let data = response.data[0]
          if(data.status){
            this.gymUserData = data.data.result;
            this.domain = data.data.domain;
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },inviteUser(item) {
        authClient.post('trainer/clients/invite', {
          "email":item.email,
          "trainer_id":item.gym_id,
          "trainer_type":"gym_trainer"
        }).then((response) => {
          let data = response.data
          if(data.success){
            this.$toast.success({
              title: 'Success',
              message: data.message,
          });
          }else{
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      getOrderList() {
        this.loading = true
        authClient.get('/siteAd/order_list').then((response) => {
          let data = response.data[0]
          if(data.status){
          this.orderList = data.data.result;
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getGymUsers();
      },
      viewDetails(item) {
        this.$router.push({
            name: 'Gym Member Details', // Use the route name
            params: { id: this.$store.state.user.id, member: item }, // Pass the 'id' parameter if needed
          });
      },
    },
    mounted() {
      this.getGymUsers();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  