<template>
    <v-card class="rounded-t-lg rounded-b-0">
      <v-card-title>
        <v-row no-gutters>
             Update Macro
          <v-spacer/>
          <v-btn color="red" icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
            <v-select :rules="[rules.required]" v-model="Fitcategory" outlined class="mr-0 mr-sm-1" label="Fitness Category"  item-value="value" :items="FitnessCategory"></v-select>
           <!-- loss progarm mode -->
            <v-select v-if="Fitcategory == 'Loss'" :rules="[rules.required]" v-model="Fitprogram" outlined class="mr-0 mr-sm-1" label="Fitness Program"  item-value="value" :items="LossFitnessProgramme"></v-select>
            <v-select v-if="Fitcategory == 'Loss'" :rules="[rules.required]" v-model="Fitmode" outlined class="mr-0 mr-sm-1" label="Fitness Program"  item-value="value" :items="LossFitnessMode"></v-select>
            <!-- gain progarm mode -->
            <v-select v-if="Fitcategory == 'Gain'" :rules="[rules.required]" v-model="Fitprogram" outlined class="mr-0 mr-sm-1" label="Fitness Program"  item-value="value" :items="GainFitnessProgramme"></v-select>
            <v-select v-if="Fitcategory == 'Gain'" :rules="[rules.required]" v-model="Fitmode" outlined class="mr-0 mr-sm-1" label="Fitness Program"  item-value="value" :items="GainFitnessMode"></v-select>
        </v-form>
      </v-card-text>
  
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="upsertPlan" dark large color="primary">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "UpdateMacro",
    data: () => ({
     Fitcategory:null,
     Fitprogram:null,
     Fitmode:null,
      rules: {
      required: value => !!value || 'Required.',
    },
    FitnessCategory: [
      {text: 'Loss', value: 'Loss'},
      {text: 'Gain', value: 'Gain'},
    ],LossFitnessProgramme: [
      {text: 'Moderate', value: 'Moderate'},
      {text: 'Intense', value: 'Intense'},
    ],LossFitnessMode: [
      {text: 'Controlled', value: 'Controlled'},
      {text: 'Accelerated', value: 'Accelerated'},
      {text: 'Super Accelerated', value: 'SuperAccelerated'},
    ],GainFitnessProgramme: [
      {text: 'Moderate', value: 'Moderate'},
      {text: 'Intense', value: 'Intense'},
      {text: 'High Active', value: 'HighActive'},
    ],GainFitnessMode: [
      {text: 'CalBoost', value: 'CalBoost'},
      {text: 'ProteinBoost', value: 'ProteinBoost'}
    ],
    valid:false,
      editSheet: false,
      userWeight:"",
      userHeight:"",
    }),
    methods:{
      close(){
        this.$emit('close')
      },
      upsertPlan(){
      this.$refs.form.validate();
      if(this.valid){
        this.loading = true;
        authClient.post(`/fitness/gym/${this.data}/macros`, 
        {
            "selectedFitCategory": this.Fitcategory, 
            "selectedFitProgram":this.Fitprogram,
            "selectedFitMode":this.Fitmode,
            "trainer_id" : this.$route.params.id,
        }).then(res=>{
            console.log(res)
            if(res){
                let response_data = res.data
                if(response_data.success){
                    this.close();
                    this.$toast.success({
                    title: 'Ok',
                    message: 'success',
                });
                }else{
                    this.loading = false;
                    this.$toast.error({
                    title: 'error',
                    message: response_data.info.error,
                });
                }
            }
        }).catch(err=>{
            console.error("Error details:", err);
          console.log(err);
        }).finally(()=>{
          this.loading = false;
        })
      }
    },
    },
    mounted() {
      this.userHeight = this.BMI_Data.height;
      this.userWeight = this.BMI_Data.weight;
    },
    props: {
      data: {
        required: true
      },
      BMI_Data: {
        required: true
      },
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  