<template>
    <v-container>
      <v-row no-gutters>
        <v-text-field v-model="search" outlined rounded label="Search Class..."></v-text-field>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="stories">
          <template v-slot:item.desc="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.desc">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.before_img="{ item }">
            <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.before_img">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.after_img="{ item }">
            <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.after_img">
              View
            </v-btn>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip
              class="ma-2"
              :color=" item.status == 1 ? 'success': 'warning'"
            >
            {{ item.status == 1 ? 'Active' : 'Disabled' }}
            </v-chip>
          </template>
  
          <template v-slot:item.qr_status="{ item }">
            {{ item.qr_status == 1 ? 'True' : 'False' }}
          </template>

          <template v-slot:item.face_status="{ item }">
            {{ item.qr_status == 1 ? 'True' : 'False' }}
          </template>

          <template v-slot:item.created_at="{ item }">
            {{  new Date(item.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZone: 'UTC'
                })
              }}
        </template>
  
          <template v-slot:item.actions="{ item }">
            <v-btn @click="statusChange(item.id, item.status == 1? 2 : 1)" icon>
              <v-icon :color=" item.status == 2 ? 'success': 'warning'"> {{ item.status == 1 ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
        <SuccessStoryDialog :story='editStory' :domain="domain" :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import SuccessStoryDialog from "@/components/SuccessStoryDialog.vue";
  
  export default {
    name: "GymAccess",
    components: {SuccessStoryDialog},
    data: () => ({
      loading: true,
      domain:'',
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      editStory: null,
      stories: [],
      headers: [
        {text: "ID", value: "id"},
        {text: "User", value: "user.name"},
        {text: "Email", value: "user.email"},
        {text: "Role", value: "user.role"},
        {text: "QR Id status", value: "qr_status"},
        {text: "Face Id status", value: "face_status"},
        {text: "Accessed Date & Time", value: "access_time"}
      ],
      search: '',
    }),
    methods: {
      getStories() {
        authClient.get('/gym-access/log').then((response) => {
          let data = response.data[0]
          if(data.status){
          this.stories = data.data.result;
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getStories();
      }
    },
    mounted() {
      this.getStories();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  