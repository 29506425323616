<template>
  <v-container>
    <v-row no-gutters class="mb-4">
      <v-card  v-if="!loading" v-bind:to="{ name: 'Product Orders', params: { categories : this.categories, products : this.products, Total_tax: total_tax, Total_amount: total_amount } }" outlined hover ripple dark class="green" width="180">
        <v-card-subtitle class="pb-0">Orders</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ order_count }}</v-card-title>
      </v-card>

      <v-card to="/shop/categories" outlined hover ripple class="ml-4" width="180">
        <v-card-subtitle class="pb-0">Categories</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-card-title>
      </v-card>
    </v-row>

    <v-row no-gutters>
      <v-text-field v-model="search" outlined rounded label="Search Products..."></v-text-field>
      <v-btn @click="addNew = true;isEdit=false;" rounded dark color="black" height="56" elevation="0" class="ml-2">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add New
      </v-btn>
    </v-row>

    <v-card outlined :loading="loading">
      <v-data-table
          :search="search"
          :headers="headers"
          :items="products">

        <template v-slot:item.image_path="{ item }">
          <v-avatar class="ma-2">
            <v-img :src="$store.state.s3ResourcesBaseUrl + item.image_path"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="editProduct(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-icon @click="showDeleteConfirmation(item, deleteProduct)" color="error">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-bottom-sheet persistent="false" scrollable  v-if="addNew" v-model="addNew" inset hide-overlay >
      <v-card class="rounded-t-lg rounded-b-0">
        <v-card-title>
          <v-row no-gutters>
            {{ isEdit ? 'Edit' : 'Add' }} Product
            <v-spacer/>
            <v-btn color="red" icon @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form v-if="!isEdit" ref="form" v-model="valid">
            <v-text-field v-model="data.name" outlined label="Name" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.description" outlined label="Description" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.code" outlined label="Product Code" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.quantity" type="number" min="0" outlined label="Quantity" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.min_qty" type="number" min="0" outlined label="Minimum Quantity" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.location" type="text" outlined label="Location" :rules="[rules.required]"></v-text-field>
            <v-autocomplete v-model="data.CategoryId" :items="categories" item-text="name" item-value="id" outlined label="Category" :rules="[rules.required]"></v-autocomplete>
            <v-file-input v-model="image" prepend-icon="" prepend-inner-icon="mdi-image" outlined label="Image" :rules="[rules.required]"></v-file-input>
          </v-form>

          <v-form v-if="isEdit" ref="formEdit" v-model="validEdit">
            <v-text-field v-model="data.name" outlined label="Name" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.description" outlined label="Description" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.code" outlined label="Product Code" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.quantity" type="number" min="0" outlined label="Quantity" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.min_qty" type="number" min="0" outlined label="Minimum Quantity" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.location" type="text" outlined label="Location" :rules="[rules.required]"></v-text-field>
            <v-autocomplete v-model="data.CategoryId" :items="categories" item-text="name" item-value="id" outlined label="Category" :rules="[rules.required]"></v-autocomplete>
            <v-file-input v-model="image" prepend-icon="" prepend-inner-icon="mdi-image" outlined label="Image"></v-file-input>
          </v-form>
        </v-card-text>

        <v-card-actions style="background: #1976d2">
          <v-spacer/>
          <v-btn v-if="!isEdit" :loading="loading" @click="addNewProduct" large color="white">
            <v-icon class="mr-2">mdi-check</v-icon>
            Save
          </v-btn>
          <v-btn v-if="isEdit" :loading="loading" @click="saveEdited" large color="white">
            <v-icon class="mr-2">mdi-check</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "ShopManager",
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
    },
    data: {
      id: null,
      name: '',
      description: '',
      code: '',
      price: 0,
      quantity: 0,
      min_qty: 0,
      location:'',
      image_path: '',
      CategoryId: null,
    },
    valid: false,
    validEdit: false,
    image: null,
    search: '',
    total_tax:0,
    total_amount:0,
    order_count:0,
    loading: true,
    addNew: false,
    isEdit: false,
    headers: [
      {text: "ID", value: "id"},
      {text: "", value: "image_path", sortable: false},
      {text: "Product Code", value: "code"},
      {text: "Category", value: "category_name"},
      {text: "Name", value: "name"},
      {text: "Price", value: "price"},
      {text: "Qty", value: 'quantity'},
      {text: "Min Qty", value: 'min_qty'},
      {text: "Location", value: 'location'},
      {text: "Actions", value: 'actions', sortable: false}
    ],
    products: [],
    categories: [],
  }),
  methods: {
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      deleteProduct(item) {
          authClient.post('status/change',{
            tableId: 5,
            status: '403',
            resultId: item.id
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getProducts()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.ad_img
            }).then((response) => {
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      resetProduct(){
        this.data = {
          id: null,
          name: '',
          description: '',
          code: '',
          price: 0,
          quantity: 0,
          image_path: '',
          CategoryId: null,
        };
        this.image = null;
      },
      editProduct(item){
        this.isEdit = true;
        this.addNew = true;
        this.data = item;
      },
      saveEdited(){
        this.$refs.formEdit.validate();
        if (this.validEdit) {
          this.loading = true;
          if(this.image === null){
            authClient.post('/ecom/products/actions/edit', this.data).then(res => {
              console.log(res);
              this.loading = false;
              this.resetProduct();
              this.close();
            }).catch(err => {
              this.loading = false;
              console.log(err);
              window.alert('Something went wrong. Please try again later.');
            });
          } else {
            authClient.post('/users/file-uploads/delete-file', {
              filePath: this.data.image_path
            }).then(rs => {
              console.log(rs);
              let formData = new FormData();
              formData.append('image', this.image);
              authClient.post('/users/file-uploads/product', formData).then(res => {
                if(res.status === 200){
                  let mData = this.data;
                  console.log(res.data);
                  mData.image_path = res.data.image_path;
                  console.log(mData);
                  authClient.post('/ecom/products/actions/edit',mData).then(res2 => {
                    if(res2.status === 200){
                      this.loading = false;
                      this.close();
                    }
                  }).catch(err => {
                    this.loading = false;
                    console.log(err);
                    window.alert('Something went wrong. Please try again later.');
                  });
                }
              }).catch(err => {
                this.loading = false;
                window.alert('Error uploading image');
              });
            });


          }


        }
      },
      addNewProduct() {
        console.log('Adding new product...');
        this.$refs.form.validate();
        if (this.valid) {
          this.loading = true;
          let formData = new FormData();
          formData.append('image', this.image);
          authClient.post('/users/file-uploads/product', formData).then(res => {
            if(res.status === 200){
              let mData = this.data;
              console.log(res.data);
              mData.image_path = res.data.image_path;
              console.log(mData);
              authClient.post('/ecom/products/actions/add',mData).then(res => {
                
                let response_data = res.data[0]
                if(response_data.status){
                  this.loading = false;
                  this.close();
                  this.data = {
                    id: null,
                    name: '',
                  };
                  this.$toast.success({
                    title: 'Ok',
                    message: response_data.message,
                });
                }else{
                  authClient.post('/users/file-uploads/delete-file', {
                        filePath: mData.image_path
                  })
                  this.loading = false;
                  this.$toast.error({
                    title: 'error',
                    message: response_data.message,
                });
                }
          }).catch(err => {
            this.loading = false;
            this.$toast.error({
                    title: 'error',
                    message: 'error',
                });
          });
            }
          }).catch(err => {
            this.loading = false;
            window.alert('Error uploading image');
          })
        }
      },
      close() {
        this.addNew = false;
        this.getProducts();
        this.getCategories();
        this.resetProduct();
      },
      getProducts() {
        this.loading = true;
        this.products = [];

        let sk = 'ALL';
        if (this.search !== '') {
          sk = this.search;
        }
        authClient.post('/ecom/products/search', {
          'search_key': this.search,
        })
            .then(response => {
              this.products = response.data;
              this.getCategories();
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            })
      },
      getCategories(){
        authClient.get('/ecom/categories/actions/list', {
          'search_key': this.search,
        }).then(res => {
          let data = res.data[0]
            if(data.status){
              this.categories = data.data.result;
              this.getCounts()
              
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
            }
        }).catch(err => {
          console.log(err);
        })
      },
      
     getCounts() {
        authClient.get('/ecom/products/get-counts').then(response => {
          this.order_count = response.data.order_count
          this.total_amount = response.data.total_amount
          this.total_tax = response.data.total_tax
          this.loading = false;
        });
      },
  },
  mounted() {
    this.getProducts();
  
    
  }
}
</script>

<style scoped>

</style>
