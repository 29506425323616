<template>
    <v-card class="rounded-t-lg rounded-b-0">
      <v-card-title>
        <v-row no-gutters>
            Workout Add
          <v-spacer/>
          <v-btn color="red" icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-select :items="plans" item-text="title"
                item-value="id"  v-model="current_subscription_id" outlined label="Select the plan: " class="rounded-lg"></v-select>
      </v-card-text>
  
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="editUser" dark large color="primary">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "AddSubscription",
    data: () => ({
      editSheet: false,
      plans: [],
      editData: {},
      current_subscription_id: null,
      gender:[
        {text:'Male',value:'male'},
        {text:'Female',value:'female'}
      ]
    }),
    methods:{
      close(){
        this.$emit('close')
      },
      getPlans(){
        this.loading = true;
        authClient.post('/fitness/workout-presets/get', {
            "trainer_id":this.$store.state.user.id
        }).then(res=>{
          this.plans = res.data;  
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
      },
      editUser(){
        let workout_plan = this.plans.find((item)=>{
                return item.id ==  this.current_subscription_id
          })
        
        if(!workout_plan){
          this.$toast.error({
                title: 'error',
                message: 'Please Select the preset',
            });
        }

        authClient.post('/fitness/gym/workouts/actions/new', 
            {
            "workout_id": -1, 
            "day_count" : workout_plan.day_count,
            "workouts" : JSON.stringify(workout_plan["workout_plan"]),
            "count" : workout_plan["workout_plan"].length,
            "client_name" : this.data.name,
            "client_email" : this.data.email,
            "title" : workout_plan["title"],
            "description" : workout_plan["title"],
            "user_id" : this.data.id,
            "trainer_id" : this.$route.params.id
          // this.close();
        }).then(res=>{
          let data = res.data 
          if(data.success){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
            this.close();
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
        }}
        );
      }
    },
    mounted() {
      this.getPlans()
      this.editData = this.data;
      this.editData.nic = this.editData.nic.toUpperCase();
      console.log(this.editData);
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>
  