<template>
  <v-container>
    <v-progress-linear class="mb-4" v-if="loading"></v-progress-linear>
    <v-row no-gutters class="mb-4">
      <span class="text-h5 black--text font-weight-bold">Add a New Resource</span>
      <v-spacer/>
      <v-btn @click="UpdateOrAddResource" elevation="0" color="primary" >
        <v-icon class="mr-2">mdi-content-save</v-icon>
        Save
      </v-btn>
    </v-row>
    <v-divider class="mb-4"/>
    <v-text-field v-model="title" outlined  label="Title"></v-text-field>
    <v-textarea v-model="description" outlined  label="Description"></v-textarea>
    <v-select v-model="selectedCategory" :items="categories" outlined  label="Category"></v-select>
    <v-file-input v-model="image"  prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Image" class="mr-1" outlined></v-file-input>
    <div style="height: 100%">
      <vue-editor style="height: 800px" v-model="article"/>
    </div>
    <div style="height: 64px;"/>
    <v-row class="mt-2" no-gutters>
      <v-col
        cols="12"
        sm="6"
              >
              <v-select v-model="selectedNote" :items="courtesyNotes" item-text="name" item-value="id" outlined  label="Courtesy Note"></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
              >
      <template >
        <div class="mr-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Last Update"
                      prepend-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :active-picker.sync="activePicker"
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </div>
      </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "AddResourceView",
  props: {
    resource: Object
  },
  data: () => ({
    activePicker:null,
    date: null,
    menu: false,
    edit:false,
    loading: false,
    title: "",
    description: "",
    article: '',
    image: null,
    imageLink: null,
    courtesyNotes:[],
    categories: [
        'General',
        'Injury',
        'Diets',
        'Exercises',
        'Hydration',
        'Nutrition',
        'Conditions',
        'Other'
    ],
    selectedCategory: null,
    selectedNote: null
  }),
  methods: {

    UpdateResource() {
      if (this.title !== '' && this.description !== '') {
        this.loading = true;
        if(this.image !== null){
          authClient.post('/users/file-uploads/delete-file',{
            filePath: this.resource.image
          }).then(() => {
            let formData = new FormData();
            formData.append('image', this.image);
            authClient.post('/users/file-uploads/resource-files',formData).then((response) => {
              authClient.post('/fitness/resources/actions/add-or-update',{
                id: this.resource.id,
                title: this.title,
                description: this.description,
                image: response.data.image,
                article: this.article,
                category: this.selectedCategory
              }).then((res) => {
                this.$router.push('/resources');
                this.loading = false;
              }).catch(() => {
                this.loading = false;
              });
            }).catch((error) => {
              console.log(error);
            });
          }).catch(() => {
            this.loading = false;
          })
        } else{
          authClient.post('/fitness/resources/actions/add-or-update',{
            id: this.resource.id,
            title: this.title,
            description: this.description,
            image: this.resource.image,
            article: this.article,
            category: this.selectedCategory,
            note_id:this.selectedNote,
            last_update:this.date
          }).then((res) => {
            console.log(res);
            this.loading = false;
            this.$router.push('/resources');
          }).catch(() => {
            this.loading = false;
          });
        }
      } else {
        window.alert('Please fill all the fields');
        this.loading = false;
      }
    },
    AddResource() {
      if(this.title !== '' && this.description !== '' && this.image !== null){
        let formData = new FormData();
        formData.append('image', this.image);
        authClient.post('/users/file-uploads/resource-files',formData).then((response) => {
          authClient.post('/fitness/resources/actions/add-or-update', {
            title: this.title,
            description: this.description,
            image: response.data.image,
            article: this.article,
            category: this.selectedCategory,
            note_id:this.selectedNote,
            last_update:this.date
          }).then((res) => {
            this.loading = false;
            this.$router.push('/resources');
          }).catch(() => {
            this.loading = false;
          });
        }).catch((error) => {
          console.log(error);
        });
      } else {
        window.alert('Please fill all the fields');
        this.loading = false;
      }
    },
    UpdateOrAddResource() {
      console.log(this.resource != null);
      if (this.resource != null) {
        this.UpdateResource();
      } else {
        this.AddResource();
      }
    },
    getNotes() {
        authClient.get('/courtesy_notes/list').then((response) => {
          let data = response.data[0]
          if(data.status){
            this.courtesyNotes = data.data.result;
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      save (date) {
        this.$refs.menu.save(date)
    },
  },
  mounted() {
    this.getNotes()
    if (this.resource != null) {
      this.edit = true;
      this.title = this.resource.title;
      this.description = this.resource.description;
      this.article = this.resource.article;
      this.selectedNote = this.resource.note_id;
      this.date =  this.resource.last_update;
      this.selectedCategory = this.resource.category;
    }
  }
}
</script>

<style scoped>

</style>
