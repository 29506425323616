
<template>
    <v-card>
      <v-card-title>
       Add a Image
        <v-spacer/>
        <v-btn color="red" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
  
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-file-input v-model="current_file_img_1"  prepend-icon="" prepend-inner-icon="mdi-paperclip" label="Image" class="mr-1" outlined></v-file-input>
          <v-row no-gutters>
            <v-select :items="notificationTypes" item-text="text" item-value="value" v-model="data.notification_type" :rules="[rules.required]" outlined label="Select The Type"/>
          </v-row>
        </v-form>
      </v-card-text>
  
  
      <v-card-actions style="background: #1976d2">
        <v-spacer/>
        <v-btn :disabled="loading" @click="saveOrUpdate" large color="white">
          <v-icon class="mr-2">mdi-check</v-icon>
          Save</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  
  export default {
    name: "UpsertCoupon",
    data:()=>({
      gymWiseData:[],
      comGyms:[],
      plans:[],
      modal2:null,
      loading:false,
      rules: {
        required: value => !!value || 'Required.',
      },
      notificationTypes: [
        {text: 'Large Size', value: '1'},
        {text: 'Small Size', value: '2'}
      ],
      activePicker:null,
      date: null,
      menu: false,
      time:null,
      current_file_img_1:null,
      activePicker:null,
      image_src_1: "",
      data: {
        id:null, 
        message:"",
        title:"",
        email:"",
        service_id:"",
        gym_id:"",
        notification_type:null
      },
      valid: false,
    }),
    methods:{
      imgUpload() {
          this.$refs.imageUploadFeild_1.click();
      },
      viewImage(event) {
        if (event) {
            this.image_src_1 = URL.createObjectURL(event.target.files[0]);
            this.current_file_img_1 = event.target.files[0]
        }
        
      },
      submitForm(){
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;

          let formData = new FormData();
          
          if(this.current_file_img_1 != null) formData.append('image', this.current_file_img_1);
          formData.append('type', this.data.notification_type);

          authClient.post('vendor-image/add',formData)
          .then((response) => {
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$emit('close');
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: response_data.message,
              });
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast.error({
                  title: 'error',
                  message: 'error',
              });
            });
      
          }else{
            this.loading = false
          }
      },
      saveOrUpdate(){
      this.loading = true;
        this.submitForm();
    },

    save (date) {
        this.$refs.menu.save(date)
    },
    emitValue() {
      const [hours, minutes] = this.time.split(':');
      this.$emit('value-emitted', `${hours}:${minutes}:00`);
    },

      close(){
        this.$emit('close');
      }
    },

    props:{
      edit:{
        type:Boolean
      },
      domain:String
    }
  }
  </script>
  
  <style scoped>
    .img-properties {
      width: 100%;
      max-width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
      border-radius: 0.25rem;
      margin: 0 auto;
      display: block;
      max-height: 400px;
      margin-top: 10px;
    }

    .img-container-1{
      position: relative;
      width: 100%;
    }

    .img-container-2{
      position: relative;
      width: 100%;
    }

    .main-container{
      width: 100%;
    }
    .main-img-container{
      background-color: #BBDEFB;
      border-radius: 10px;
      min-height: 15vw;
    }

    .img-container{
      width: 100%;
      display: flex;
      
    }

    .upload-lable{
      position: absolute;
      top: 1rem; /* You can adjust the top margin as needed */
      margin-left: 1rem; /* You can adjust the left margin as needed */
      z-index: 1;
    }
  </style>
  