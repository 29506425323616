import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"rounded-t-lg rounded-b-0"},[_c(VCardTitle,[_c(VRow,{attrs:{"no-gutters":""}},[_vm._v(" Workout Add "),_c(VSpacer),_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardText,[_c(VSelect,{staticClass:"rounded-lg",attrs:{"items":_vm.plans,"item-text":"title","item-value":"id","outlined":"","label":"Select the plan: "},model:{value:(_vm.current_subscription_id),callback:function ($$v) {_vm.current_subscription_id=$$v},expression:"current_subscription_id"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","large":"","color":"primary"},on:{"click":_vm.editUser}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }