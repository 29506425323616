<template>
    <v-container>
      <v-row no-gutters class="mb-4">
      <v-card outlined hover ripple dark class="green mr-2" width="180">
        <v-card-subtitle class="pb-0">All Therapies</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ therapyCount  }}</v-card-title>
      </v-card>
      <v-card to="/therapy-revenue" outlined hover ripple dark class="warning" min-width="180" width="auto">
        <v-card-subtitle class="pb-0">Therpies Revenue</v-card-subtitle>
        <v-card-title class="pt-0 text-h4"> {{ amount_total }}</v-card-title>
      </v-card>
    </v-row>
      <v-row no-gutters class="mb-0">
        <v-text-field v-model="search" outlined rounded label="Search Thrapies..." append-icon="mdi-magnify" @keyup.enter="getTherapies()" @click:append="getTherapies()"></v-text-field>
        <v-btn @click="addUserDialog = true; editTherapy = null; isEdit = false" class="ml-2" rounded height="56" elevation="0" dark>
          <v-icon>mdi-plus</v-icon>
          Add User
        </v-btn>
      </v-row>

      <v-row gutters class="mt-0">
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <div>
        <v-select @change="getTherapies()" :items="payingTypes" v-model="payingType" class="rounded-lg" item-text="text" item-value="value"  outlined label="Paying Type"/>
    </div>
        </v-col>
        <v-col
              class="pb-0"
              cols="12"
              md="6"
              lg="4"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="rounded-lg"
                outlined
                readonly
                v-model="dateRangeText"
                label="Date Range"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dates= []"
              >
                Reset
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date);getTherapies()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
              cols="12"
              md="3"
              lg="2"
        >
              <v-btn
              @click="getTherapies(1)"
              :style="{ width: '100%' }"
                x-large
                color="warning"
                dark
              :loading="loading"
              >
              <v-icon
                left
                dark
              >
              mdi-tray-arrow-down
              </v-icon>
                Export Pdf
              </v-btn>
        </v-col>
        <v-col
              cols="12"
              md="3"
              lg="2"
        >
              <v-btn
              @click="downloadCsv()"
              :loading="loading"
              :style="{ width: '100%' }"
                x-large
                color="warning"
                dark
              >
              <v-icon
                left
                dark
              >
              mdi-tray-arrow-down
              </v-icon>
                Export Csv
              </v-btn>
        </v-col>
      </v-row>
  
      <v-card outlined :loading="loading"  class="mt-5">
        <v-data-table
            :headers="headers"
            :items="therapies">
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" @click="editUserDetails(item)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn color="red"  @click="showDeleteConfirmation(item, statusChange)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="viewDetails_revenue(item)" v-bind="attrs"
                     v-on="on" icon>
                <v-icon>mdi-file-account-outline</v-icon>
              </v-btn>

            </template>
            <span>Revenue</span>
          </v-tooltip>
          </template>
  
          <template v-slot:item.working_type="{ item }">
            {{ item.working_type == '1' ? 'Full Time' : 'Specific Time' }}
          </template>

          <template v-slot:item.paying_type="{ item }">
            {{ item.paying_type == '1' ? 'Per Hour' : 'Per Session' }}
          </template>
        </v-data-table>
      </v-card>
  
  
      <v-dialog max-width="768" persistent v-if="addUserDialog" v-model="addUserDialog">
        <AddTherepyDialog :therapy='editTherapy' :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import Papa from 'papaparse';
  import AddTherepyDialog from "@/components/AddTherepyDialog.vue";
  
  export default {
    name: "Add Physio Therapy",
    components: {AddTherepyDialog},
    data: () => ({
      search: '',
      date:null,
      menu: false,
      modal: false,
      menu2: false,
      dates: [],
      therapyCount:0,
      amount_total:0,
      loading: true,
      therapies: [],
      payingType:'0',
      payingTypes: [
        {text: 'All', value: '0'},
        {text: 'Hourly Rate', value: '1'},
        {text: 'Session', value: '2'}
      ],
      headers: [
        {text: "User ID", value: "user_id"},
        {text: "Name", value: "name"},
        {text: "Email", value: "email"},
        {text: "Phone", value: 'phone'},
        {text: "Paying Type", value: 'paying_type'},
        {text: "Working Type", value: 'working_type'},
        {text: "Actions", value: "actions", sortable: false}
      ],
      addUserDialog: false,
      editTherapy: null,
      isEdit: false
    }),
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
      downloadCsv() {
      // Replace this with your actual CSV data
      const csvData = this.therapies.map(item => ({
        UserId: item.user_id,
        RegDate: item.created_at,
        Name: item.name,
        Nationality: item.country_code,
        Gender: item.gender,
        Email: item.email,
        Phone: item.phone,
        PaytingType: item.paying_type == 1 ? 'Hourly Rate' : 'Session',
        }));;

        const csv = Papa.unparse(csvData);

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${Date.now()}_therapy_insights.csv`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      editUserDetails(user) {
        this.editTherapy = user;
        this.isEdit = true;
        this.addUserDialog = true;
      },
      closeDialogs(){
        this.getTherapies();
        this.getCounts();
        this.addUserDialog = false;
        this.editTherapy = null;
        this.isEdit = false;
      },
      // getTherapies() {
      //   authClient.get('/therapy/list').then((response) => {
      //     let data = response.data[0]
      //     if(data.status){
      //       this.therapies = data.data.result;
      //       this.domain = data.data.domain;
      //     }else{
      //       this.loading = false;
      //       this.$toast.error({
      //         title: 'error',
      //         message: data.message,
      //     });
      //     }
      //     this.loading = false;
      //   }).catch((error) => {
      //     console.log(error);
      //     this.loading = false;
      //   });
      // },
      statusChange(item) {
        authClient.post('/status/change',{
            status: '403',
            tableId: 1,
            resultId: item.user_id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getTherapies();
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
    },
      getCountsRevenue() {
        this.loading = true;
        authClient.get('therapy/counts').then(response => {
          this.amount_total = response.data.amount_total;
          this.loading = false;
        }).catch(error => {
          console.log(error);
          this.loading = false;
        });
      },
      getTherapies(type=0) {
        this.loading = true;
        authClient.post('/therapy/list',{
          'dateRange':this.dates,
          'payingType' : this.payingType,
          'search' : this.search,
          'type' : type,
        }, {responseType:  type == 0? '':'blob'}).then(response => {

          if(type == '1'){

            const href = window.URL.createObjectURL(response.data);

            const anchorElement = document.createElement('a');

            anchorElement.href = href;
            anchorElement.download = `${Date.now()}_theraphy_insights.pdf`;
            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);

          }else{
            this.therapies = response.data;
          
          }
          this.loading = false;
        });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      viewDetails_revenue(item){
      this.$router.push({name:'Single of Psychotherapy', params:{member:item}});
    },
      getCounts() {
      this.loading = true;
      authClient.get('/dashboard/counts').then(response => {
        this.therapyCount = response.data.therapy_count;
        this.loading = false;
        console.log('NEW');
        console.log(response.data);
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
    },
    mounted() {
      this.getTherapies();
      this.getCounts();
      this.getCountsRevenue()
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  