import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_c(VRow,{attrs:{"no-gutters":""}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.edit ? 'Edit':'Add')+" User")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VDivider,{staticClass:"mb-4"}),_c(VCardText,[_c(VForm,{ref:"MainForm"},[_c(VTextField,{attrs:{"outlined":"","rules":[_vm.rules.required],"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{attrs:{"outlined":"","rules":[_vm.rules.required],"label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"outlined":"","rules":_vm.repeatPasswordRules,"label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VTextField,{staticClass:"ml-2",attrs:{"outlined":"","rules":_vm.repeatPasswordRules,"label":"Confirm Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c(VTextField,{attrs:{"outlined":"","rules":[_vm.rules.required],"label":"Phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c(VTextField,{attrs:{"outlined":"","rules":[_vm.rules.required],"label":"NIC"},model:{value:(_vm.nic),callback:function ($$v) {_vm.nic=$$v},expression:"nic"}}),_c(VSelect,{attrs:{"outlined":"","label":"Type","item-value":"value","items":_vm.UserTypes},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c(VRow,{attrs:{"justify":"end","no-gutters":""}},[_c(VBtn,{attrs:{"loading":_vm.loading,"width":"128","elevation":"0","height":"56","color":"primary"},on:{"click":_vm.upsertUser}},[_vm._v("Save")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }