import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"rounded-t-lg rounded-b-0"},[_c(VCardTitle,[_c(VRow,{attrs:{"no-gutters":""}},[_vm._v(" Update Blood Pressure Data "),_c(VSpacer),_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"type":"number","placeholder":"Systolic (mmHg)","rules":[_vm.rules.required],"outlined":"","label":"Systolic (mmHg)"},model:{value:(_vm.systolicAmount),callback:function ($$v) {_vm.systolicAmount=$$v},expression:"systolicAmount"}}),_c(VTextField,{attrs:{"type":"number","placeholder":"Diastolic (mmHg)","rules":[_vm.rules.required],"outlined":"","label":"Diastolic (mmHg)"},model:{value:(_vm.diastolicAmount),callback:function ($$v) {_vm.diastolicAmount=$$v},expression:"diastolicAmount"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","large":"","color":"primary"},on:{"click":_vm.upsertPlan}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }