import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[[_c(VRow,{staticClass:"pt-15 pb-5"},[_c(VCheckbox,{attrs:{"color":"red","label":"Select All"},on:{"change":_vm.toggleSelectAll},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VRow,_vm._l((_vm.selectedWidget),function(item,index){return _c(VCol,{attrs:{"sm":"6"}},[_c(VCheckbox,{key:item.id,attrs:{"value":item.id,"label":item.title},model:{value:(_vm.accesedPaths),callback:function ($$v) {_vm.accesedPaths=$$v},expression:"accesedPaths"}})],1)}),1)],1)],_c(VRow,[_c(VBtn,{staticClass:"my-4",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.upsertPlan()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-check")]),_vm._v(" Save ")],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }