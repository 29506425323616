<template>
  <v-container>
    <v-col>
      <GymUserDetailsHeading @close="getCurrentWokouts()" :data="member"  class="mb-4"/>
        <v-divider class="mb-4"/>
    </v-col>
    <v-row >
      <v-col cols="5">
        <v-card class="ma-2 rounded-lg" width="100%" hover ripple v-if="plans!=null">
          <v-card-body class="d-flex justify-space-around"  @click="viewDetails()">
            <div>
              <v-card-title>
                {{ member.name }}
                <v-spacer/>
              </v-card-title>
              <v-card-subtitle>Steps: {{ plans.completed_steps + ' /  ' + plans.totalSteps }}</v-card-subtitle>
            </div>
            <div>
              <v-progress-circular class="mt-3"
                  :value="Math.floor((plans.completed_steps / plans.totalSteps) * 100)"
                  :size="75"
                  :width="10"
                  color="green"
                  >
                  {{ Math.floor((plans.completed_steps / plans.totalSteps) * 100) }} %
              </v-progress-circular>
            </div>
          
        </v-card-body>
          <v-card-actions>
            <v-row no-gutters>
              <!-- <v-btn @click="showDeleteConfirmation(plan, deletePlan)" color="red" text>Delete Coupon</v-btn> -->
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="6">
            <UserBloodPresure :user_id="member.id" :HelthData="HelthData.blood_pressure"  @render="getHEalthData" v-if="HelthDataLoaded" />
        </v-col>
      <v-col cols="6">
            <UserMacro :user_id="member.id" :HelthData="HelthData.macros"  @render="getHEalthData" v-if="HelthDataLoaded"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
            <UserBMIData :user_id="member.id" :HelthData="HelthData.bmi_pi"  @render="getHEalthData" v-if="HelthDataLoaded"/>
        </v-col>
        <v-col cols="6">
            <UserFatData :user_data="member"   :HelthData="HelthData.body_fat"  @render="getHEalthData" v-if="HelthDataLoaded"/>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
            <UserBloodSuger :user_id="member.id" :HelthData="HelthData.blood_sugar"  @render="getHEalthData" v-if="HelthDataLoaded" />
        </v-col>
        
    </v-row>
    <v-row>
      <v-col cols="12">
            <UserMesurements :user_id="member.id" :HelthData="HelthData.misc"  @render="getHEalthData" v-if="HelthDataLoaded" />
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GymUserDetailsHeading from "@/components/GymUserDetailsHeading";
import UserFatData from "@/components/UserFatData";
import UserBMIData from "@/components/UserBMIData";
import UserBloodSuger from "@/components/UserBloodSuger";
import UserBloodPresure from "@/components/UserBloodPresure";
import UserMesurements from "@/components/UserMesurements";
import UserMacro from "@/components/UserMacro";
import {authClient} from "@/plugins/http";

export default {
  name: "MemberDetails",
  components: {GymUserDetailsHeading, UserBMIData, UserFatData, UserBloodSuger,UserBloodPresure, UserMesurements, UserMacro},
  data: () => ({
      plans: null,
      HelthData : {},
      HelthDataLoaded: false
    }),
  props:{
    member: {
      type: Object,
      required: true
    }
  },
  methods:{
    getDetails(){
      return [
        {name: 'ID', value: this.member.id},
        {name: 'Email', value: this.member.email},
      ]
    },
    getCurrentWokouts(){
      this.loading = true;
      authClient.post('/fitness/gym/workouts/actions/view',{
        "trainer_id" : this.$route.params.id,
        "user_id" : this.member.id
      }).then(res=>{
        this.plans = res.data[0];
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
    },
    getHEalthData(){
        this.loading = true;
        authClient.get(`/fitness/${this.member.id}/fitness-data`,{
          userID:this.user_id
        }).then(value => {
          this.HelthData = value.data;
          this.HelthDataLoaded = true;
          this.loading = false;
        });
      },
    viewDetails() {
        this.$router.push({
            name: 'Gym Member Workouts', // Use the route name
            params: { id: this.$store.state.user.id, member: this.member }, // Pass the 'id' parameter if needed
          });
      },
  },
  mounted() {
    if(!this.member){
        this.$router.push('/gym-user-manage');
    }
    this. getHEalthData(),
    this.getCurrentWokouts()
  }
}
</script>

<style scoped>

</style>
