<template>
    <div>
        <v-card  class="mb-4">
            <v-card-title>Add Exercise</v-card-title>
            <v-card-text>
              <v-row  class="mb-n4">
                <v-col
                  cols="12"
                  sm="9"
                >
                <v-select item-value="id" item-text="title" v-model="selectedExcercise" :items="exercises" outlined  label="Select Exercise"></v-select>
                </v-col> 
          
              <v-col
                  cols="12"
                  sm="3"
                >
                <v-select  v-model="selected_date" :items="days" outlined  label="Select Day"></v-select>
              </v-col> 
            
          </v-row>
                
                <v-row>
                    <v-col
                    cols="12"
                    sm="4"
                    >
                        <v-text-field v-model="repetitions" type="number" label="Reps"></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="4"
                    >
                        <v-text-field v-model="weight" type="number"  label="Weight (kg)"></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="4"
                    >
                        <v-text-field v-model="sets" type="number" label="Sets"></v-text-field>
                    </v-col>
                </v-row>
                <v-textarea class="mx-2" label="Notes" rows="2" v-model="notes"></v-textarea>
                <v-btn @click="addField">Add Field</v-btn>
            </v-card-text>
        </v-card>
      
  
      <v-card v-for="(field, index) in fields" :key="index" class="mb-4">
        <v-card-title>
          {{ field.title }}
          <v-spacer></v-spacer>
          <v-btn @click="editField(index)">Edit</v-btn>
          <v-btn @click="deleteField(index)">Delete</v-btn>
        </v-card-title>
        <v-card-text>
          {{ field.description }}
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";

  export default {
    name: "ExcercisePresetAdd",
    watch: {
    days_count: {
      immediate: true, 
      handler(newVal) {
        console.log(newVal)
        // Recalculate the items array
        this.days = [...Array(parseInt(newVal, 10)).keys()].map(i => i + 1);
        this.fields = [];
      },
    },
  },
    props: {
      days_count: Number,
      edited: Boolean,
      feild_data: Array,
  },
    data() {
      return {
        selectedExcercise: null,
        notes: null,
        repetitions:null,
        weight:null,
        sets:null,
        selected_date:null,
        fields: [],
        days:[],
        exercises:[]
      };
    },
    methods: {
      addField() {

        if (this.notes && this.selectedExcercise &&  this.repetitions && this.weight && this.sets && this.selected_date > 0) {
          
          const result = this.fields.find(obj => (obj.id == this.selectedExcercise &&  this.selected_date == obj.day));
          if(result){
            
            this.$toast.error({
                  title: 'error',
                  message: "Selected Excersice Already Added",
            });
            return;
          }

          this.fields.push({
            ...this.exercises.find((item)=>{
                return item.id ==  this.selectedExcercise
            }),
            repetitions: this.repetitions,
            has_completed: false,
            sets: this.sets,
            notes : this.notes,
            day: this.selected_date,
            weight : this.weight
          });
          this.selectedExcercise = "";
          this.notes = "";
          this.repetitions = "";
          this.weight = "";
          this.sets = "";
          this.$emit('qualification-emitted', this.fields);
        }else{
            this.$toast.error({
                title: 'error',
                message: 'Fill All Feilds',
            });
        }
      },
      editField(index) {
        const field = this.fields[index];
        this.selectedExcercise = field.id;
        this.notes = field.notes;
        this.repetitions = field.repetitions;
        this.weight = field.weight;
        this.selected_date = field.day;
        this.sets = field.sets;
        this.deleteField(index);
      },
      deleteField(index) {
        this.fields.splice(index, 1);
        this.$emit('qualification-emitted', this.fields);
      },
      getExercises() {
        authClient.get('/fitness/workouts/actions/get-all').then((response) => {
            this.exercises = response.data;
            console.log(response);
            this.loading = false;
        }).catch((error) => {
            console.log(error);
            this.loading = false;
        });
    },
    
    },
    mounted() {
        this.getExercises()
      setTimeout(() => {
      if(this.edited){
        this.fields = this.feild_data
    }
      }, 200);
     
  }
  };
  </script>
  
  <style>
  /* Add your styles here */
  </style>
  