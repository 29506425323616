<template>
    <v-container>
      <v-row no-gutters>
        <v-text-field v-model="search" outlined rounded label="Search Image..."></v-text-field>
        <v-btn @click="addStoryDialog = true;editExercise = null; isEdit = false" class="ml-2" rounded height="56" elevation="0" dark>
          <v-icon>mdi-plus</v-icon>
          Add Image
        </v-btn>
      </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :search="search"
            :headers="headers"
            :items="stories">
          <template v-slot:item.message="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.message">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.image="{ item }">
            <v-btn v-if="item.image != ''" outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.image">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.after_img="{ item }">
            <v-btn outlined rounded target="_blank" :href="$store.state.s3ResourcesBaseUrl  + item.after_img">
              View
            </v-btn>
          </template>

          <template v-slot:item.avatar="{ item }">
          <v-avatar class="ma-2">
            <v-img :src="$store.state.s3ResourcesBaseUrl + item.avatar"></v-img>
          </v-avatar>
        </template>
  
          <template v-slot:item.type="{ item }">
          <v-chip dark>{{ item.type == 1 ? "Large Size" : "Small Size"}}</v-chip>
        </template>
  
          <template v-slot:item.actions="{ item }">
            <v-btn @click="showDeleteConfirmation(item, deleteStory)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addStoryDialog" v-model="addStoryDialog">
        <AddVendorImage :story='editStory' :domain="domain" :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import AddVendorImage from "@/components/AddVendorImage.vue";
  
  export default {
    name: "CustomNotification",
    components: {AddVendorImage},
    data: () => ({
      loading: true,
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      editStory: null,
      stories: [],
      headers: [
        {text: "", value: "avatar", sortable: false},
        {text: "Image", value: "image"},
        {text: "Type", value: "type"},
        {text: "Actions", value: "actions"},
      ],
      search: '',
    }),
    methods: {
      deleteStory(item) {
          authClient.post('delete',{
            tableId: 23,
            resultId: item.id
          }).then((response) => {
            
            authClient.post('/users/file-uploads/delete-file',{
              filePath: item.image
            });
            
              this.$toast.success({
                title: 'Ok',
                message: 'Success',
              });
              this.getStories()
    
           
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          
            
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      getStories() {
        authClient.get('/vendor-image/list').then((response) => {
          let data = response.data
          this.stories = data;
          this.loading = false;
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getStories();
      }
    },
    mounted() {
      this.getStories();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  