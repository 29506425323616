import { render, staticRenderFns } from "./ReportSingleTherapy.vue?vue&type=template&id=10dde400&scoped=true&"
import script from "./ReportSingleTherapy.vue?vue&type=script&lang=js&"
export * from "./ReportSingleTherapy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10dde400",
  null
  
)

export default component.exports